import React, { useState } from "react";
import "./Adjuster.css";
const Adjuster = () => {
  // Array of items with prices and discounts
  const items = [
    {
      name: "Item 1",
      price: 30,
      discounts: {
        25: 0, // No discount at quantity 25
        50: 10, // 10% discount at quantity 50
        75: 15, // 15% discount at quantity 75
      },
    },
    {
      name: "Item 2",
      price: 40,
      discounts: {
        25: 5, // 5% discount at quantity 25
        50: 12, // 12% discount at quantity 50
        75: 18, // 18% discount at quantity 75
      },
    },
    {
      name: "Item 3",
      price: 50,
      discounts: {
        25: 3, // 3% discount at quantity 25
        50: 8, // 8% discount at quantity 50
        75: 20, // 20% discount at quantity 75
      },
    },
    {
      name: "Item 4",
      price: 60,
      discounts: {
        25: 7, // 7% discount at quantity 25
        50: 15, // 15% discount at quantity 50
        75: 25, // 25% discount at quantity 75
      },
    },
  ];

  // State to track the selected item and quantity
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [quantity, setQuantity] = useState(25);

  // Get the selected item's details
  const selectedItem = items[selectedItemIndex];

  // Calculate the discount and total price
  const discountRate = selectedItem.discounts[quantity];
  const priceBeforeDiscount = selectedItem.price * quantity;
  const discountAmount = (priceBeforeDiscount * discountRate) / 100;
  const priceAfterDiscount = priceBeforeDiscount - discountAmount;
  const totalPriceBeforeDiscount = priceBeforeDiscount * quantity;
  const totalPriceAfterDiscount = priceAfterDiscount * quantity;
  const totalSaving = totalPriceBeforeDiscount - totalPriceAfterDiscount;   

  return (
    <div className="bg-white mx-4 sm:mx-0 sm:w-[480px] sm:h-[620px] rounded-lg" data-aos="zoom-in">
      <div className=" flex items-center justify-center">
        <div className=" rounded-lg shadow-lg p-4 sm:p-8 w-full">
          <h1 className="text-3xl font-bold text-center text-_dark mb-4">
            ADJUST & SAVE
          </h1>
          <p className="text-center text-_gray text-sm mb-6">
            Find the perfect deal by adjusting the quantity of your purchase!
            See how much you can save when you buy more.
          </p>

          <div className="mb-6 border py-3 px-2 rounded-sm shadow-sm">
            <select
              className="w-full text-_gray focus:ring-0 focus:outline-none  "
              onChange={(e) => setSelectedItemIndex(Number(e.target.value))}
              value={selectedItemIndex}
            >
              {items.map((item, index) => (
                <option key={index} value={index} className="px-2 focus:ring-0 focus:outline-none">
                  {item.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-6">
            <p className="text-lg font-semibold text-_dark mb-4">
              Select your count
            </p>
            <div className="flex items-center justify-between">
              <span>25</span>
              <input
                type="range"
                min="0"
                max="2"
                step="1"
                value={quantity === 25 ? 0 : quantity === 50 ? 1 : 2}
                onChange={(e) => setQuantity([25, 50, 75][e.target.value])}
                className="w-full mx-4"
              />
              <span>75</span>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row justify-between items-center mb-6 gap-4 ">
            <div className=" p-4 rounded-lg shadow-md w-full sm:w-1/2">
              <div className="flex flex-row justify-between text-xs font-semibold">
                <p className="text-_gray">Standard Price</p>
                <p className="text-_gray">Size : {quantity}</p>
              </div>
              <div className="flex flex-row justify-between text-xs text-_white bg-_gray py-1 px-1 rounded-sm my-8">
                <p className="">For one item</p>
                <p className="">Rs. {priceBeforeDiscount}</p>
              </div>
              <div className="flex flex-row justify-between items-center text-xs text-_white   rounded-sm ">
                <p className="text-_gray">Total</p>
                <p className="bg-_gray py-1 px-3 rounded-full">
                  Rs. {totalPriceBeforeDiscount}
                </p>
              </div>
            </div>
            {/* <i className="fas fa-arrow-right text-2xl text-gray-500"></i> */}
            <div className=" p-4 rounded-lg shadow-md w-full sm:w-1/2">
              <div className="flex flex-row justify-between text-xs font-semibold text-_gray">
                <p className="">Discount price</p>
                <p className="">Size : {quantity}</p>
              </div>
              <div className="flex flex-row justify-between text-xs text-_white bg-_dark py-1 px-1 rounded-sm my-8">
                <p className="">For one item</p>
                <p className="">Rs. {priceAfterDiscount}</p>
              </div>
              <div className="flex flex-row justify-between items-center text-xs text-_white   rounded-sm ">
                <p className="text-_gray">Total</p>
                <p className="bg-_dark py-1 px-3 rounded-full">
                  Rs. {totalPriceAfterDiscount}
                </p>
              </div>
            </div>
          </div>

          <div className="text-center">
            <p className="text-2xl font-semibold text-_dark">
              You Saved!
            </p>
            <p className="text-3xl font-bold text-_red">Rs. {totalSaving}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Adjuster;
