import React from "react";
import {
  RiCellphoneFill,
  RiFacebookCircleFill,
  RiMailFill,
  RiPhoneFill,
  RiWhatsappFill,
} from "react-icons/ri";

const SocialBar = () => {
  return (
    <div className="h-screenx top-1/2 -translate-y-20  fixed  right-2 lg:right-6 xl:right-20 z-20 flex flex-col items-center justify-center">
      <div className="w-full flex flex-col items-end">
        <div className="flex flex-col items-center justify-center gap-4 px-2 py-4 text-2xl bg-_dark rounded-full text-_white font-bold shadow-lg">
          <button className=" group">
            <RiWhatsappFill />
            <p className=" hidden group-hover:block absolute text-nowrap right-12  -translate-y-7 text-base border border-_dark_lite p-3 rounded-md backdrop-blur-md shadow-lg bg-_dark bg-opacity-40">
              Lorem, ipsum.
            </p>
          </button>
          <button className=" group">
            <RiFacebookCircleFill />

            <p className=" hidden group-hover:block absolute text-nowrap right-12  -translate-y-7 text-base border border-_dark_lite p-3 rounded-md backdrop-blur-md shadow-lg bg-_dark bg-opacity-40">
              Follow us on facebook
            </p>
          </button>
          <button className=" group">
            <RiMailFill />
            <p className=" hidden group-hover:block absolute text-nowrap right-12  -translate-y-7 text-base border border-_dark_lite p-3 rounded-md backdrop-blur-md shadow-lg bg-_dark bg-opacity-40">
              doctorhub.lk@gmail.com
            </p>
          </button>
          <button className=" group">
            <RiPhoneFill />
            <div className=" hidden group-hover:block absolute text-nowrap right-12  -translate-y-7 text-base border border-_dark_lite p-3 rounded-md backdrop-blur-md shadow-lg bg-_dark bg-opacity-40">
              <div className="flex flex-col items-end">
                <div className="flex flex-row items-center justify-center gap-2">
                  <div className="flex flex-col items-end">
                    <h2 className="text-base font-light">
                      Lorem, ipsum. (peradeniya)
                    </h2>
                    <p className="text-xl">0764511038</p>
                  </div>
                  <div className="h-10 w-1 bg-_gray_hover"></div>
                </div>
                <div className="flex flex-row items-center justify-center gap-2">
                  <div className="flex flex-col items-end">
                    <h2 className="text-base font-light">
                      Lorem, ipsum. (peradeniya)
                    </h2>
                    <p className="text-xl">0764511038</p>
                  </div>
                  <div className="h-10 w-1 bg-_gray_hover"></div>
                </div>
                <div className="flex flex-row items-center justify-center gap-2">
                  <div className="flex flex-col items-end">
                    <h2 className="text-base font-light">
                      Lorem, ipsum. (peradeniya)
                    </h2>
                    <p className="text-xl">0764511038</p>
                  </div>
                  <div className="h-10 w-1 bg-_gray_hover"></div>
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SocialBar;
