import React from 'react';

const YouTubeEmbedVideo = ({ videoId , title = 'YouTube video player', width = 560, height = 315 }) => {
  return (
    <div className="relative overflow-hidden w-full pt-0" style={{ paddingBottom: '56.25%' }}>
      <iframe
        className="absolute top-0 left-0 w-full h-full"
        src={`https://www.youtube.com/embed/${videoId}`}
        title={title}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default YouTubeEmbedVideo;