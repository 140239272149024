import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import { fetchMonthlyIncome } from '../../api/dashboardApi';

// This is where you would normally import your API function

const MonthlyIncomeChart = () => {
  const [formattedData, setFormattedData] = useState([]);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Use a simple theme detection based on prefers-color-scheme
  const [isDarkMode, setIsDarkMode] = useState(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
  );

  useEffect(() => {
    // Listen for changes in color scheme preference
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => setIsDarkMode(e.matches);
    
    if (mediaQuery?.addEventListener) {
      mediaQuery.addEventListener('change', handleChange);
      return () => mediaQuery.removeEventListener('change', handleChange);
    }
  }, []);

  // Helper function to format dates from "YYYY-MM" to "Month Year"
  const formatMonthYear = (dateStr) => {
    const [year, month] = dateStr.split('-');
    const date = new Date(parseInt(year), parseInt(month) - 1);
    return date.toLocaleDateString(undefined, { month: 'short', year: 'numeric' });
  };

  useEffect(() => {
    const getIncome = async () => {
      setLoading(true);
      try {
        // Use the passed-in fetchMonthlyIncome function
        const data = await fetchMonthlyIncome();
        
        if (data && data.length > 0) {
          // Format the data for display
          const formatted = data.map(item => ({
            name: formatMonthYear(item.name),
            total: parseFloat(item.total)
          }));
          
          setFormattedData(formatted);
          
          // Calculate min and max values for the Y-axis domain
          const values = formatted.map(d => d.total);
          const minVal = Math.min(...values);
          const maxVal = Math.max(...values);
          
          setMinValue(minVal * 0.9);
          setMaxValue(maxVal * 1.1);
        }
      } catch (err) {
        setError("Failed to load income data");
        console.error("Error fetching income data:", err);
      } finally {
        setLoading(false);
      }
    };

    // Call the function to get income data
    getIncome();
  }, [fetchMonthlyIncome]);
  
  // Theme colors
  const textColor = isDarkMode ? "#94a3b8" : "#475569";
  const lineColor = "#2563eb";
  const activeColor = "#1e40af";
  const tooltipBg = isDarkMode ? "#1e293b" : "#ffffff";
  const tooltipText = isDarkMode ? "#cbd5e1" : "#334155";

  if (loading) {
    return <div className="flex items-center justify-center h-64">
      <p className="text-lg text-slate-600 dark:text-slate-400">Loading income data...</p>
    </div>;
  }

  if (error) {
    return <div className="flex items-center justify-center h-64">
      <p className="text-lg text-red-500">{error}</p>
    </div>;
  }

  return (
    <div className="card-body p-0">
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={formattedData}
          margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
        >
          <Tooltip 
            cursor={false} 
            formatter={(value) => [`LKR ${value.toLocaleString()}`, "Income"]} 
            contentStyle={{
              background: tooltipBg,
              border: "none",
              borderRadius: "0.5rem",
              boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1)"
            }}
            labelStyle={{
              color: tooltipText,
              fontWeight: "bold"
            }}
          />

          <XAxis
            dataKey="name"
            strokeWidth={0}
            stroke={textColor}
            tickMargin={6}
            tick={{ fill: textColor }}
          />

          <YAxis
            strokeWidth={0}
            stroke={textColor}
            tickFormatter={(value) => `LKR ${value.toLocaleString()}`}
            tickMargin={6}
            domain={[minValue, maxValue]}
            tick={{ fill: textColor }}
          />

          <Line
            type="monotone"
            dataKey="total"
            stroke={lineColor}
            strokeWidth={2}
            dot={{ r: 6, fill: lineColor, strokeWidth: 0 }}
            activeDot={{ r: 8, fill: activeColor, strokeWidth: 0 }}
            name="Income"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MonthlyIncomeChart;