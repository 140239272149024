import { useEffect, useState } from "react";
import { fetchUserOrders } from "../../api/authApi";

const MyOrders = () => {
    const [orders, setOrders] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const token = sessionStorage.getItem("token");

        if (!token) {
            setError("No token found. Please log in.");
            return;
        }

        fetchUserOrders(token)
            .then((data) => {
                if (data?.data) {
                    // Ensure `orderItems` is parsed properly
                    const parsedOrders = data.data.map(order => ({
                        ...order,
                        orderItems: JSON.parse(order.orderItems || "[]")
                    }));
                    setOrders(parsedOrders);
                } else {
                    setOrders([]);
                }
            })
            .catch(() => setError("Failed to fetch orders."));
    }, []);

    return (
        <div className=" mx-auto mt-8 p-6 bg-_gray_hover rounded-sm shadow-md text-_dark">
            <h2 className="text-2xl font-semibold mb-4">My Orders</h2>
            {error ? (
                <p className="text-_red font-medium">{error}</p>
            ) : orders.length === 0 ? (
                <p className="text-_gray">No orders found.</p>
            ) : (
                <div className="space-y-6">
                    {orders.map(order => (
                        <div key={order.orderId} className="bg-_white p-4 rounded-lg">
                            <div className="flex justify-between items-center border-b pb-2 mb-3">
                                <h3 className="text-lg font-semibold">Order #{order.orderId}</h3>
                                <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                                    order.paymentMethod == 1 ? "bg-_gray_lite text-green-700" : "bg-_gray_hover text-_gray"
                                }`}>
                                    {order.paymentMethod == 1 ? "Online" : "Cash On Delivery"}
                                </span>
                            </div>

                            <p className="text-_gray">
                                <strong>Total:</strong> LKR {order.amount}
                            </p>
                            <p className="text-_gray">
                                <strong>Shipping Address:</strong> {order.shippingAddress}, {order.city}
                            </p>

                            <h4 className="mt-3 font-semibold">Items:</h4>
                            <ul className="list-disc pl-5 space-y-2 text-_gray">
                                {order.orderItems.map(item => (
                                    <li key={item.orderItemId}>
                                        <strong>{item.title}</strong> - {item.count} pcs (LKR {item.unitPrice.toFixed(2)} each)
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MyOrders;
