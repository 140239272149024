import React from 'react'

const StorePriceFilter = ({setPage, minPrice, setMinPrice, maxPrice, setMaxPrice}) => {
      const handleCheckboxChange = (min, max, checked) => {
        // setPage(1);
        if (checked) {
          setMinPrice(min);
          setMaxPrice(max);
        } else {
          setMinPrice("");
          setMaxPrice("");
        }
      };
  return (
    <div className="">
    <h2 className="text-start mb-4 text-sm font-bold">Price</h2>

    <div className="space-y-2">
      {/* Predefined Price Ranges */}
      <label className="flex items-center">
        <input
          type="checkbox"
          className="form-checkbox h-4 w-4 text-_dark"
          onChange={(e) =>
            handleCheckboxChange(0, 1500, e.target.checked)
          }
          checked={minPrice === 0 && maxPrice === 1500}
        />
        <span className="ml-2 text-start text-sm font-semibold">
          Under 1500 LKR
        </span>
      </label>

      <label className="flex items-center">
        <input
          type="checkbox"
          className="form-checkbox h-4 w-4 text-_dark"
          onChange={(e) =>
            handleCheckboxChange(1500, 10000, e.target.checked)
          }
          checked={minPrice === 1500 && maxPrice === 10000}
        />
        <span className="ml-2 text-start text-sm font-semibold">
          1500 - 10000 LKR
        </span>
      </label>

      <label className="flex items-center">
        <input
          type="checkbox"
          className="form-checkbox h-4 w-4 text-_dark"
          onChange={(e) =>
            handleCheckboxChange(0, 10000, e.target.checked)
          }
          checked={minPrice === 0 && maxPrice === 10000}
        />
        <span className="ml-2 text-start text-sm font-semibold">
          Under 10000 LKR
        </span>
      </label>

      {/* Custom Price Input */}
      <div className="mt-8">
        <div className="flex space-x-4">
          <input
            type="text"
            placeholder="Min"
            value={minPrice}
            onChange={(e) => setMinPrice(e.target.value)}
            className="border border-_gray border-opacity-50 p-2 rounded w-full text-_gray focus:outline-_gray focus:ring-1"
          />
          <span className="self-center">to</span>
          <input
            type="text"
            placeholder="Max"
            value={maxPrice}
            onChange={(e) => setMaxPrice(e.target.value)}
            className="border border-_gray border-opacity-50 p-2 rounded w-full text-_gray focus:outline-_gray focus:ring-1"
          />
        </div>
      </div>
    </div>
  </div>
  )
}

export default StorePriceFilter