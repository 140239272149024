import { BrowserRouter, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "./Admin/contexts/theme-context"; // Adjust path if needed
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AOS from 'aos';
import 'aos/dist/aos.css'; // AOS styles

// Main App Components
import Navbar from './components/Navbar/Navbar';
import Landing from './Pages/Landing/Landing';
import Store from './Pages/Store/Store';
import Login from './Pages/auth/Login';
import Register from './Pages/auth/Register';
import ProductPage from './Pages/product/Product';
import CartPage from './Pages/cart/Cart';
import Profile from './Pages/profile/Profile';
import Checkout from './Pages/payment/Checkout';
// import Footer from './components/Footer/Footer';

// Admin Components
import Layout from "./Admin/routes/layout"; // Adjust path
import AdminDashboard from "./Admin/routes/dashboard/page"; // Adjust path

import Footer from "./components/Footer/Footer";
import { CartProvider } from "./Contexts/CartContext/CartContext";

import ContactPage from "./Pages/Contact/ContactPage";
import CreateProduct from "./Admin/pages/products/NewProduct";
import AllProducts from "./Admin/pages/products/AllProducts";
import EditProduct from "./Admin/pages/products/EditProduct";
import Orders from "./Admin/pages/orders/Orders";
import Customers from "./Admin/pages/customers/Custormers";
import About from "./Pages/about/About";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import { useState, useRef, useEffect } from "react";
import TitleUpdater from "./components/titleUpdater/TitleUpdater";
import ReactGA from 'react-ga4';
import Batchweise from "./components/Batchwise/Batchweise";
import Batch from "./Pages/batchwise/Batch";

ReactGA.initialize('G-9XC2V6R395'); 

function AppContent() {
  const location = useLocation();
  const navigate = useNavigate();
  const isAdminRoute = location.pathname.startsWith("/admin");
  const isAuthRoute = location.pathname.startsWith("/auth/login") || 
                      location.pathname.startsWith("/auth/register");

  const [search, setSearch] = useState("");
  const [user, setUser] = useState(null);
  const [isLogged, setIsLogged] = useState(false);
  
  // Store previous search value to detect changes
  const prevSearchRef = useRef("");
  
    // google analytics
    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);
  // Effect to navigate to store page when search changes
  useEffect(() => {
    // Skip the first render (initial state)
    if (prevSearchRef.current !== search && search !== "") {
      navigate("/store");
    }
    // Update the previous search value
    prevSearchRef.current = search;
  }, [search, navigate]);
  
useEffect(() =>
{
  const token = sessionStorage.getItem("token");
  if(token)
  {
    setIsLogged(true);
  }
}, [])

useEffect(() => {
  AOS.init({
    duration: 800,
    easing: 'ease-in-out',
    once: true,
  });
}, []);
  return (
    <>
      {!isAdminRoute && !isAuthRoute &&  <Navbar search = {search} setSearch = {setSearch} user = {user} setUser = {setUser} isLogged = {isLogged} setIsLogged = {setIsLogged} />}

      <ScrollToTop/>
      <TitleUpdater/>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Landing />} />
        <Route path="auth/register" element={<Register setIsLogged = {setIsLogged} />} />
        <Route path="auth/login" element={<Login setIsLogged = {setIsLogged} />} />
        <Route path="store" element={<Store search = {search}/>} />
        <Route path="store/:categorySlug" element={<Store />} />
        <Route path="product/:id" element={<ProductPage isLogged = {isLogged} />} />
        <Route path="profile" element={<Profile />} />
        <Route path="cart" element={<CartPage />} />
        <Route path="checkout" element={<Checkout />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="batchwise" element={<Batch />} />
        <Route path="about" element={<About />} />

        {/* Admin Routes */}
        <Route path="/admin" element={<Layout />}>
          <Route index element={<AdminDashboard />} />
          <Route path="analytics" element={<h1 className="title">Analytics</h1>} />
          <Route path="reports" element={<h1 className="title">Reports</h1>} />
          <Route path="customers" element={<Customers/>} />
          <Route path="new-customer" element={<h1 className="title">New Customer</h1>} />
          <Route path="verified-customers" element={<h1 className="title">Verified Customers</h1>} />
          <Route path="products" element={<AllProducts/>} />
          <Route path="product/edit/:id" element={<EditProduct/>} />
          <Route path="new-product" element={<CreateProduct/>} />
          <Route path="orders" element={<Orders/>} />
          <Route path="settings" element={<h1 className="title">Settings</h1>} />
        </Route>
      </Routes>
      {!isAdminRoute && !isAuthRoute && <Footer />}
      <ToastContainer position="top-right" autoClose={3000} />
    </>
  );
}

function App() {
  return (
    <ThemeProvider storageKey="theme">
      <CartProvider>
      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
      </CartProvider>
    </ThemeProvider>
  );
}

export default App;