import { useEffect, useState } from "react";
import { fetchCartProducts } from "../../api/productApi";
import { Link } from "react-router-dom";
import { useCart } from "../../Contexts/CartContext/CartContext";
// import { clearCart } from "../../Contexts/CartContext/CartContext";
import CartLoadingSkeleton from "./CartSkeleton"; 

export default function CartPage() {
  const { cartItems, updateQuantity, removeFromCart } = useCart();
  const [loading, setLoading] = useState(true);
  const [productDetails, setProductDetails] = useState({});
  const { clearCart } = useCart();
  
  useEffect(() => {
    const loadCart = async () => {
      try {
        // If you have an API that fetches product details, use it here
        const data = await fetchCartProducts();
        if (data) {
          // Create a map of subProductId to product details
          const detailsMap = {};
          data.forEach(item => {
            detailsMap[item.subProductId] = {
              title: item.title,
              price: item.price,
              product : item.product,
              // Add any other details you need
            };
          });
          setProductDetails(detailsMap);
        }
      } catch (error) {
        console.error("Error loading cart data:", error);
      } finally {
        setLoading(false);
      }
    };
    
    loadCart();
  }, []);

  const totalPrice = cartItems.reduce((sum, item) => {
    // Use item.price if available, fallback to product details price
    const price = item.price || (productDetails[item.subProductId]?.price || 0);
    return sum + price * item.count;
  }, 0);

  if (loading) return <div className=""><CartLoadingSkeleton/></div>;

  if (cartItems.length === 0) return <div className="text-center p-10 mt-36 text-_dark">Your cart is empty!</div>;

  return (
    <div className="max-w-screen-lg mx-8 lg:mx-auto mt-36 md:mt-48">
      <h1 className="text-3xl font-bold mb-8 text-_dark">Your Cart</h1>
      {cartItems.map((item) => (
        <div key={item.subProductId} className="flex flex-col md:flex-row items-center justify-start md:justify-between border-b py-4 gap-4 md:gap-0">
          <div className="w-full md:w-auto flex flex-col items-start ">
            <h2 className="text-_dark text-sm md:text-base font-semibold line-clamp-2">
              {productDetails[item.subProductId]?.product || "Item Name"}
            </h2>
            <h3 className="text-_gray text-xs line-clamp-1">
              {item.title || productDetails[item.subProductId]?.title || "Product Variant"}
            </h3>
            <p className="text-_dark">Rs. {item.price || productDetails[item.subProductId]?.price || 0}</p>
          </div>
          <div className="w-full md:w-auto flex items-center justify-end gap-4">
            <button
              className="px-3 py-1 bg-_gray_lite text-_dark rounded"
              onClick={() => updateQuantity(item.subProductId, item.count - 1)}
            >
              -
            </button>
            <span className="text-lg text-_dark">{item.count}</span>
            <button
              className="px-3 py-1 bg-_gray_lite text-_dark rounded"
              onClick={() => updateQuantity(item.subProductId, item.count + 1)}
            >
              +
            </button>
            <button
              className="px-4 py-2 bg-_red text-white rounded hover:bg-opacity-90"
              onClick={() => removeFromCart(item.subProductId)}
            >
              Remove
            </button>
          </div>
        </div>
      ))}
      <div className="text-right mt-6">
        <h2 className="text-xl font-bold text-_dark">Total: Rs. {totalPrice}</h2>
        <Link to="/checkout">
          <button className="mt-4 px-6 py-2 bg-_dark text-white rounded-lg hover:bg-opacity-90">
            Proceed to Checkout
          </button>
        </Link>
      </div>
    </div>
  );
}