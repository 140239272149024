import React, { useEffect } from "react";
import iSamsung from "../../assets/sponsors/samsung.png";
import iLittmann from "../../assets/sponsors/littmann.png";
import Gallery from "../../assets/gallery.png";

function About() {
  return (
    <div className="py-20 flex justify-center items-center bg-_white mt-36">
      <div className="px-6 lg:px-0 max-w-screen-lg w-full">
        {/* Hero Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-24">
          <div className="flex flex-col justify-center space-y-6">
            <h1 className="text-4xl text-_dark md:text-5xl font-bold text-myDark">
              About DOCTORHub.lk
            </h1>
            <div className="h-1 w-24 bg-_dark_lite rounded"></div>
            <p className="text-lg text-_gray leading-relaxed">
              DOCTORHub.lk is a premier provider of high-quality medical equipment owned and operated by two Sri Lankan medical students. Our mission is to supply fellow medical students with authentic medical products at the most competitive prices in the market.
            </p>
          </div>
          <div className="flex justify-center items-center">
            <img 
              src={Gallery} 
              alt="Medical Equipment Collection" 
              className="h-auto max-h-[500px] rounded-lg  object-cover" 
            />
          </div>
        </div>

        {/* Value Proposition Section */}
        <div className="mb-24">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-myDark mb-4 text-_dark">Why Choose DOCTORHub.lk ?</h2>
            <div className="h-1 w-24 bg-_dark_lite rounded mx-auto"></div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div className="bg-_white p-8 rounded-lg shadow-md hover:shadow-lg transition duration-300">
              <h3 className="text-xl font-semibold text-myDark mb-4 text-_dark">
                Your Trusted Partner in Medical Solutions
              </h3>
              <p className="text-_gray">
                At DOCTORHub.lk, we are dedicated to providing top-quality medical equipment to healthcare professionals and students throughout Sri Lanka. With a commitment to authenticity and innovation, we ensure that our customers receive the best tools for their education and practice.
              </p>
            </div>
            
            <div className="bg-_white p-8 rounded-lg shadow-md hover:shadow-lg transition duration-300">
              <h3 className="text-xl font-semibold text-myDark mb-4 text-_dark">
                Reliable Equipment for Every Need
              </h3>
              <p className="text-_gray">
                From stethoscopes to diagnostic tools, our inventory includes essential equipment needed by medical students and professionals. Our products are known for their durability, precision, and competitive pricing, helping future healthcare professionals build their toolkit without breaking the bank.
              </p>
            </div>
          </div>
        </div>

        {/* Statistics Section */}
        <div className="bg-_gray_lite rounded-lg shadow-xl p-12 my-24 text-_dark">
          <h2 className="text-3xl font-bold text-center mb-12">Our Impact</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
            <div className="p-4">
              <h3 className="text-5xl font-bold mb-2">1500+</h3>
              <p className="text-xl">Satisfied Customers</p>
            </div>
            
            <div className="p-4">
              <h3 className="text-5xl font-bold mb-2">2000+</h3>
              <p className="text-xl">Successful Transactions</p>
            </div>
            
            <div className="p-4">
              <h3 className="text-5xl font-bold mb-2">50+</h3>
              <p className="text-xl">Medical Schools Served</p>
            </div>
          </div>
        </div>

        {/* Partners Section */}
        <div className="mt-36 hidden">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-myDark mb-4 text-_dark">Our Trusted Partners</h2>
            <div className="h-1 w-24 bg-_dark_lite rounded mx-auto"></div>
          </div>
          
          <div className="flex flex-wrap justify-center gap-16 items-center p-8 bg-_white rounded-lg shadow-md mt-16">
            <img 
              src={iSamsung} 
              alt="Samsung" 
              className="h-16 md:h-20 grayscale hover:grayscale-0 transition duration-300" 
            />
            <img 
              src={iLittmann} 
              alt="Littmann" 
              className="h-16 md:h-20 grayscale hover:grayscale-0 transition duration-300" 
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;