import React from 'react'
import Batchweise from '../../components/Batchwise/Batchweise'

const Batch = () => {
  return (
    <div className='bg-gradient-to-tl from-_bg_lite mb-16 to-_bg_dark'>
        <Batchweise/>
    </div>
  )
}

export default Batch