import React from "react";

const ConfirmationModal = ({ 
  isOpen, 
  onClose, 
  onConfirm, 
  title, 
  message, 
  confirmText = "Confirm", 
  cancelText = "Cancel", 
  children 
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-_dark bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-xl font-bold mb-4">{title}</h2>
        {message && <p className="mb-4">{message}</p>}
        
        {children && <div className="mb-4">{children}</div>}
        
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="border py-2 px-4 text-_dark font-semibold rounded-full hover:bg-gray-100"
          >
            {cancelText}
          </button>
          <button
            onClick={onConfirm}
            className="border bg-myRed py-2 px-4 text-_dark font-semibold rounded-full"
          >
            {confirmText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;