import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { loginUser } from "../../api/authApi";
import GoogleAuth from '../../components/googleAuth/GoogleAuth';
import { Link, useNavigate } from "react-router-dom";

const Login = ({setIsLogged}) => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const navigate = useNavigate();
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //check is user have already login session
  useEffect(()=>{
    const token = sessionStorage.getItem("token");
    if(token){
        toast.warn("you have already login!");
        navigate("/profile");
    }
  },[])

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = await loginUser(formData);
      toast.success(data.message); 
      sessionStorage.setItem("token",data.token);
      setIsLogged(true);
      
      const redirect = sessionStorage.getItem("redirect");
      redirect ? navigate(redirect) : navigate("/")
      
    } catch (err) {
      toast.error(err); 
    }
  };

  return (
    <div className="flex flex-col lg:flex-row items-center justify-between h-screen bg-_white ">
      <div className="hiddenx lg:blockx">
      <div className="  w-full h-48 sm:h-64 lg:h-screen flex flex-col justify-center items-start p-8 gap-4 bg-[url('https://img.freepik.com/premium-photo/cheerful-attractive-doctor-medical-uniform-posing-with-stethoscope-badge-hospital_359031-19169.jpg')] bg-no-repeat bg-cover bg-_bg_lite bg-blend-multiply text-_white">
       <h1 className="text-3xl lg:text-6xl font-bold">Welcome Back to DoctorHub.lk</h1>
       <p className="text-sm sm:text-lg font-light">Welcome back. Log in to manage your orders and explore Sri Lanka’s best medical accessories.</p>
       </div>
      </div>
      <div className=" w-full lg:w-1/2 flex justify-center items-center  sm:mt-8 lg:mt-0">
      <div className="bg-_white  shadow-md rounded-md w-full sm:w-96 p-8 m-8">
        <h2 className="text-xl font-bold mb-4">Login</h2>
        
        <form onSubmit={handleSubmit} className="space-y-3">
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-2 border rounded-md focus:ring-0 focus:outline-none"
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            className="w-full p-2 border rounded-md focus:ring-0 focus:outline-none"
          />
          <button type="submit" className="w-full bg-_dark hover:bg-opacity-90 text-_white py-2 rounded-md">
            Login
          </button>
        </form>
        <div className="mt-4 flex justify-center items-center">
          <GoogleAuth setIsLogged = {setIsLogged}/>
        </div>
        <p className="text-_gray text-center text-sm pt-4">
        Don't have an account? <span className="hover:underline"><Link to={"/auth/register"}>Register here</Link></span>
        </p>
        <p className="text-_gray text-center text- ">
        or
        </p>
        <button className="w-full bg-_gray_lite hover:bg-_gray_hover text-_dark py-2 rounded-md">
         <span className="hover:underline"><Link to={"/"}>Access as a guest</Link></span>
        </button>

      </div>
      </div>
    </div>
  );
};

export default Login;
