import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { registerUser } from "../../api/authApi";
import GoogleAuth from "../../components/googleAuth/GoogleAuth";
import { Link, useNavigate } from "react-router-dom";

const Register = ({setIsLogged}) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    password: "",
  });
  const navigate = useNavigate();

  //check is user have already login session
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      toast.warn("you have already login!");
      navigate("/");
    }
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = await registerUser(formData);
      toast.success(data.message);
      sessionStorage.setItem("token",data.token);
      navigate("/");
    } catch (err) {
      toast.error(err);
    }
  };

  return (
    <div className="flex flex-col lg:flex-row items-center justify-center h-screen bg-white-100 mt-">
      <div className="hiddenx lg:blockx w-full">
      <div className=" w-full h-68 sm:h-68 lg:h-screen flex flex-col justify-center items-start pb-8 pt-48 md:pt-48 lg:pt-0 p-4 gap- bg-[url('https://img.freepik.com/premium-photo/cheerful-attractive-doctor-medical-uniform-posing-with-stethoscope-badge-hospital_359031-19169.jpg')] bg-no-repeat bg-cover bg-_bg_lite bg-blend-multiply text-_white">
       <h1 className="  text-4xl lg:text-6xl font-bold">Join Sri Lanka’s Trusted Medical Marketplace</h1>
       <p className="text-xs lg:text-lg font-light">Create your DoctorHub.lk account to access quality medical accessories and healthcare supplies. Connect with trusted suppliers and streamline your orders.</p>
       </div>
      </div>
      <div className=" w-full lg:w-1/2 flex justify-center items-center sm:mt-8 lg:mt-0">
      <div className="bg-_white border   rounded-md w-full sm:w-96 p-2 sm:p-8 m-2 sm:m-8">
        <h2 className="text-xl font-bold mb-4">Register</h2>

        <form onSubmit={handleSubmit} className="space-y-3">
          {[
            "firstName",
            "lastName",
            "email",
            "phone",
            "address",
            "city",
            "password",
          ].map((field) => (
            <input
              key={field}
              type={field === "password" ? "password" : "text"}
              name={field}
              placeholder={field}
              value={formData[field]}
              onChange={handleChange}
              className="w-full p-2 border rounded-md focus:ring-0 focus:outline-none"
            />
          ))}
          <button
            type="submit"
            className="w-full bg-_dark hover:bg-opacity-90 text-_white py-2 rounded-md"
          >
            Register
          </button>

          <div className="flex justify-center items-center w-full ">
            <GoogleAuth setIsLogged = {setIsLogged} />
          </div>
        </form>
        <p className="text-_gray text-center text-sm pt-4">
        Already have an account? <span className="hover:underline"><Link to={"/auth/login"}>Login here</Link></span>
        </p>
        <p className="text-_gray text-center text- ">
        or
        </p>
        <button className="w-full bg-_gray_lite hover:bg-_gray_hover text-_dark py-2 rounded-md">
         <span className="hover:underline"><Link to={"/"}>Access as a guest</Link></span>
        </button>
        
      </div>
      </div>
    </div>
  );
};

export default Register;
