import { use, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchProductById, fetchReviews } from "../../api/productApi";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ProductSection from "./ProductSection";
import ProductDescription from "./ProductDescription";
import ProductReview from "./ProductReview";
import { useCart } from "../../Contexts/CartContext/CartContext";
import LoadingSkeleton from "./ProductSkeleton ";
import AddReview from "./AddReview";
import YouTubeEmbedVideo from "./YouTubeEmbedVideo";

export default function ProductPage({isLogged}) {
  const [product, setProduct] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [selectedSubProduct, setSelectedSubProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [isLoading, setIsloading] = useState(true);
  const [title, setTitle] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();
  const { cartItems, addToCart, removeFromCart } = useCart();

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchProductById(id);
        if (data) {
          setProduct(data);
          if (data.subProducts?.length > 0) {
            setSelectedSubProduct(data.subProducts[0]);
          }
          setTitle(data?.productDetails?.title);
        }
      } catch (error) {
        console.error("Error fetching product:", error);
      } finally {
        setIsloading(false);
      }
    };

    loadData();
  }, [id]);

  useEffect(() => {
    const getReviews = async () => {
      try {
        const data = await fetchReviews(id);
        setReviews(data.reviews);
      } catch (error) {
        console.error("Failed to load reviews");
      }
    };

    getReviews();
  }, [id]);

  useEffect(() => {
    if (selectedSubProduct) {
      const cartItem = cartItems.find(
        (item) => item.subProductId === selectedSubProduct.subProductId
      );
      setQuantity(cartItem ? cartItem.count : 1);
    }
  }, [selectedSubProduct, cartItems]);

  useEffect(() => {
    document.title = `DOCTORHub.lk  ${product?.productDetails?.title || ""}`;
  },[title])
  const handleAddToCart = () => {
    const existingItem = cartItems.find(
      (item) => item.subProductId === selectedSubProduct.subProductId
    );

    if (existingItem) {
      // Remove item if already in cart
      removeFromCart(selectedSubProduct.subProductId);
    } else {
      // Add new item with all necessary information
      addToCart({
        subProductId: selectedSubProduct.subProductId,
        count: quantity,
        price: selectedSubProduct.price || product.price,
        productName: product.title, // Main product name
        title: selectedSubProduct.title || "Standard", // Subproduct title/variant
        image: product.image || selectedSubProduct.image,
      });
    }
  };

  const handleBuyNow = () => {
    const existingItem = cartItems.find(
      (item) => item.subProductId === selectedSubProduct.subProductId
    );

    if (!existingItem) {
      addToCart({
        subProductId: selectedSubProduct.subProductId,
        count: quantity,
        price: selectedSubProduct.price || product.price,
        productName: product.title, // Main product name
        title: selectedSubProduct.title || "Standard", // Subproduct title/variant
        image: product.image || selectedSubProduct.image,
      });
    }

    navigate("/cart");
  };

  console.log(reviews);
  return (
    <>
      {!isLoading ? (
        <div className="w-full flex flex-col items-center justify-center px-4 sm:px-8 lg:px-4 mt-32 md:mt-44 lg:mt-48">
          <div className="w-full max-w-screen-xl ">
            <div className="py-8">
              <Breadcrumb globalProductName={product?.productDetails?.title} />
            </div>
            <ProductSection
              product={product}
              selectedSubProduct={selectedSubProduct}
              quantity={quantity}
              setQuantity={setQuantity}
              cart={cartItems}
              handleAddToCart={handleAddToCart}
              handleBuyNow={handleBuyNow}
              setSelectedSubProduct={setSelectedSubProduct}
            />
            <div className="pt-24 pb-12">{/* <ProductRelated /> */}</div>
            <div className="py-16">
              <ProductDescription
                description={product?.productDetails?.description}
              />
            </div>
            <div>{product?.productDetails?.ytVideoId && <YouTubeEmbedVideo videoId={product?.productDetails?.ytVideoId} />}</div>
            <div className="py-8">
              <ProductReview reviews={reviews} />
              <AddReview mainProductId={id} isLogged = {isLogged} />
            </div>
          </div>
        </div>
      ) : (
        <LoadingSkeleton />
      )}
    </>
  );
}
