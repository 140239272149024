import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { fetchUserProfile, updateUserProfile } from "../../api/authApi";
import MyOrders from "./MyOrders";
import { useNavigate } from "react-router-dom";
import ProfileSkeleton from "./ProfileSkeleton";

export default function Profile() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    phone: "",
    address: "",
    city: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = sessionStorage.getItem("token");
        if (!token) {
          toast.warning("You are not logged in.");
          navigate("/auth/login");
          setError("You are not logged in.");
          setLoading(false);
          return;
        }

        const data = await fetchUserProfile(token); // Use the helper function

        setUser(data);
        setFormData({
          phone: data.phone || "",
          address: data.address || "",
          city: data.city || "",
        });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);
  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem("token");
      if (!token) {
        setError("You are not logged in.");
        return;
      }
  
      const updatedData = await updateUserProfile(token, formData); // Use helper function
      toast.success("Profile updated successfully!");
  
      // Update state with new user data
      setUser((prevUser) => ({
        ...prevUser,
        ...formData, // Merge updated fields into user state
      }));
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) return <div className="text-center p-10 mt-36"><ProfileSkeleton/></div>;
  if (error)
    return <div className="text-center p-10 text-_red mt-36">{error}</div>;

  return (
    <div className="max-w-screen-lg mx-auto bg-_white shadow-md rounded-sm p-6 mt-28 md:mt-40">
      <h1 className="text-3xl font-semibold text-_dark pb-8">Profile</h1>
      {/* Profile Image */}
      <div className="flex flex-row justify-start items-center gap-4">
        <img
          src={user.profileImg}
          alt="Profile"
          className="size-16 rounded-full border border-_gray_lite"
          referrerPolicy="no-referrer"
        />
        <div className="flex flex-col justify-center items-start">
        <h1 className="text-2xl text-_dark font-semibold ">
          {user.firstName} {user.lastName}
        </h1>
        <p className="text-_gray text-xs md:text-sm">{user.email}</p>
          
        </div>
      </div>

      {(!user.address || !user.phone || !user.city) && (
        <div className="mt-4 text-center text-_dark">
          <h3>
            Please complete the following details to ensure smooth order
            delivery:
          </h3>
        </div>
      )}

      <form onSubmit={handleUpdate} className="mt-6 space-y-4">
        <input
          type="text"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="Phone"
          className="w-full p-2 border border-_gray_lite rounded focus:outline-0 focus:outline-none"
        />
        <input
          type="text"
          name="address"
          value={formData.address}
          onChange={handleChange}
          placeholder="Address"
          className="w-full p-2 border border-_gray_lite rounded focus:outline-0 focus:outline-none"
        />
        <input
          type="text"
          name="city"
          value={formData.city}
          onChange={handleChange}
          placeholder="City"
          className="w-full p-2 border border-_gray_lite rounded focus:outline-0 focus:outline-none"
        />
        <button
          type="submit"
          className="w-full bg-_dark text-_white py-2 rounded hover:bg-opacity-90"
        >
          Update Profile
        </button>
      </form>

      <MyOrders/>
    </div>
  );
}
