export const getRelativeTime = (dateString) => {
    const date = new Date(dateString.split('/').reverse().join('/')); // Convert DD/MM/YYYY to Date object
    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);
    
    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60
    };
  
    if (seconds < 60) return "Just now";
    
    for (const [unit, secondsInUnit] of Object.entries(intervals)) {
      const interval = Math.floor(seconds / secondsInUnit);
      if (interval >= 1) {
        return interval === 1 ? `1 ${unit} ago` : `${interval} ${unit}s ago`;
      }
    }
    
    return "Just now";
  };
  
  // Alternative version for today/yesterday
  export const getSimpleRelativeTime = (dateString) => {
    const date = new Date(dateString.split('/').reverse().join('/'));
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    
    if (date.toDateString() === today.toDateString()) {
      return "Today";
    } else if (date.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    }
    return getRelativeTime(dateString);
  };