import React, { useState, useRef } from 'react';
import { Upload, X } from 'lucide-react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const ImageUploadSection = ({ productId, onSuccess, onError }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [crop, setCrop] = useState({ 
    unit: '%', 
    width: 100,
    height: 100,
    x: 0,
    y: 0 
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [uploading, setUploading] = useState(false);
  const imageRef = useRef(null);
  const fileInputRef = useRef(null);

  // Handle file selection
  const handleFileSelect = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedFile(file);
      
      // Create preview URL
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle crop change
  const onCropChange = (crop, percentCrop) => {
    setCrop(percentCrop);
  };

  // Handle crop complete
  const onCropComplete = (crop) => {
    setCompletedCrop(crop);
  };

  // Get cropped image blob
  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    // Set canvas size to the cropped size
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;

    // Draw the cropped image
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    // Get canvas as blob
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg', 0.95);
    });
  };

  // Upload the cropped image
  const uploadImage = async () => {
    if (!completedCrop || !imageRef.current) {
      return;
    }

    try {
      setUploading(true);
      // Get the cropped image blob
      const croppedImageBlob = await getCroppedImg(imageRef.current, completedCrop);
      
      // Create file from blob
      const file = new File([croppedImageBlob], selectedFile.name, { 
        type: 'image/jpeg',
        lastModified: new Date().getTime()
      });
      
      // Create form data
      const formData = new FormData();
      formData.append('productImg', file);

      // Get token from session storage
      const token = sessionStorage.getItem('token');
      
      if (!token) {
        throw new Error('Authentication token not found. Please log in again.');
      }

      // Upload to server
      const response = await fetch(`http://localhost:5005/api/products/img/${productId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error(`Failed to upload image: ${response.status}`);
      }

      // Reset
      setSelectedFile(null);
      setPreviewUrl(null);
      setCrop({ unit: '%', width: 100, height: 100, x: 0, y: 0 });
      setCompletedCrop(null);
      
      // Notify parent component
      onSuccess('Image uploaded successfully');
    } catch (err) {
      onError(`Error uploading image: ${err.message}`);
    } finally {
      setUploading(false);
    }
  };

  // Cancel image selection
  const cancelUpload = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
    setCrop({ unit: '%', width: 100, height: 100, x: 0, y: 0 });
    setCompletedCrop(null);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <h3 className="text-lg font-medium text-gray-900 mb-4">Upload New Image</h3>
      
      {!previewUrl ? (
        <div className="border-2 border-dashed border-gray-300 rounded-lg p-6">
          <div className="flex flex-col items-center justify-center text-center">
            <Upload className="h-12 w-12 text-gray-400" />
            <p className="mt-2 text-sm text-gray-600">
              Click to select an image or drag and drop
            </p>
            <p className="text-xs text-gray-500 mt-1">PNG, JPG, GIF up to 5MB</p>
            <input
              ref={fileInputRef}
              type="file"
              className="hidden"
              accept="image/*"
              onChange={handleFileSelect}
            />
            <button
              type="button"
              onClick={() => fileInputRef.current.click()}
              className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Select Image
            </button>
          </div>
        </div>
      ) : (
        <div className="space-y-4">
          <div className="border rounded-lg overflow-hidden">
            <ReactCrop
              crop={crop}
              onChange={onCropChange}
              onComplete={onCropComplete}
              aspect={1}
            >
              <img
                ref={imageRef}
                src={previewUrl}
                alt="Preview"
                className="max-w-full"
              />
            </ReactCrop>
          </div>
          <p className="text-sm text-gray-600">
            Drag to adjust the crop area, then click Upload to save
          </p>
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={cancelUpload}
              className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <X className="mr-2 h-4 w-4" />
              Cancel
            </button>
            <button
              type="button"
              onClick={uploadImage}
              disabled={uploading}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            >
              {uploading ? (
                <>
                  <div className="mr-2 h-4 w-4 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                  Uploading...
                </>
              ) : (
                <>
                  <Upload className="mr-2 h-4 w-4" />
                  Upload
                </>
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageUploadSection;