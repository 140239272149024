import React from 'react'
import bg from '../../assets/Batchwise-bg.png'
import Adjuster from './components/Adjuster'

function Batchweise() {
  return (
    <div className='flex flex-col items-center justify-center pt-40 pb-48 bg-gradient-to-b from-transparent to-white '>
      <h1 className='mx-8 lg:mx-0 text-6xl lg:text-8xl text-center font-semibold text-_white max-w-screen-lg z-20 ' data-aos="fade-up">Exclusive Batch-Wise Purchases for Medical Students</h1>
      <img src={bg} alt="" className='h-[800px]  absolute opacity-20 overflow-hidden object-cover'/>
      <div className='z-20 pt-12 flex flex-col items-center justify-center'>
      <img src={bg} alt="" className='max-w-screen-mdx h-[540px] overflow-hidden object-cover'/>
      <div className='absolute object-bottom translate-y-96 sm:translate-y-72 ' >
      <Adjuster/>
      </div>
      </div>
    </div>
  )
}

export default Batchweise
