import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Save, Trash2, RefreshCw, ArrowLeft, Image, X, Plus } from 'lucide-react';
import ImageUploadSection from './ImgCropper';

const ProductEditPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  
  const [productData, setProductData] = useState({
    productDetails: {
      title: '',
      price: 0,
      discountPrice: 0,
      description: '',
      status: 1
    },
    subProducts: [],
    images: []
  });
  
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [deletingImageId, setDeletingImageId] = useState(null);
  const [deletingSubProductId, setDeletingSubProductId] = useState(null);
  const [isAddingSubProduct, setIsAddingSubProduct] = useState(false);
  const [newSubProduct, setNewSubProduct] = useState({
    title: '',
    price: 0
  });
  const [showImageUpload, setShowImageUpload] = useState(false);

  const fetchProductDetails = async () => {
    try {
      const response = await fetch(`http://localhost:5005/api/products/${id}`);
      
      if (!response.ok) {
        throw new Error(`Failed to fetch product: ${response.status}`);
      }
      
      const data = await response.json();
      setProductData(data);
      setLoading(false);
    } catch (err) {
      setError(`Error loading product: ${err.message}`);
      setLoading(false);
    }
  };

  // Fetch product data
  useEffect(() => {
    if (id) {
      fetchProductDetails();
    }
  }, [id]);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    let processedValue = value;
    
    setProductData({
      ...productData,
      productDetails: {
        ...productData.productDetails,
        [name]: processedValue
      }
    });
  };

  // Handle new sub-product form changes
  const handleSubProductChange = (e) => {
    const { name, value } = e.target;
    setNewSubProduct({
      ...newSubProduct,
      [name]: value
    });
  };

  // Save product changes
  const handleSave = async (e) => {
    e.preventDefault();
    setSaving(true);
    setError(null);
    setSuccessMessage('');

    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`http://localhost:5005/api/products/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            title: productData.productDetails.title,
            price: productData.productDetails.price,
            discountPrice: productData.productDetails.discountPrice,
            description: productData.productDetails.description,
        })
      });

      if (!response.ok) {
        throw new Error(`Failed to update product: ${response.status}`);
      }

      setSuccessMessage('Product updated successfully');
      setSaving(false);
    } catch (err) {
      setError(`Error saving product: ${err.message}`);
      setSaving(false);
    }
  };

  // Delete product (set status to inactive)
  const handleDeleteProduct = async () => {
    if (!window.confirm('Are you sure you want to delete this product?')) {
      return;
    }

    setSaving(true);
    setError(null);
    setSuccessMessage('');

    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`http://localhost:5005/api/products/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ status: 0 })
      });

      if (!response.ok) {
        throw new Error(`Failed to delete product: ${response.status}`);
      }

      setProductData(prev => ({
        ...prev,
        productDetails: {
          ...prev.productDetails,
          status: 0
        }
      }));
      setSuccessMessage('Product deleted successfully');
      setSaving(false);
    } catch (err) {
      setError(`Error deleting product: ${err.message}`);
      setSaving(false);
    }
  };

  // Restore product (set status to active)
  const handleRestoreProduct = async () => {
    setSaving(true);
    setError(null);
    setSuccessMessage('');

    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(`http://localhost:5005/api/products/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ status: 1 })
      });

      if (!response.ok) {
        throw new Error(`Failed to restore product: ${response.status}`);
      }

      setProductData(prev => ({
        ...prev,
        productDetails: {
          ...prev.productDetails,
          status: 1
        }
      }));
      setSuccessMessage('Product restored successfully');
      setSaving(false);
    } catch (err) {
      setError(`Error restoring product: ${err.message}`);
      setSaving(false);
    }
  };

  // Delete image
  const handleDeleteImage = async (imageId) => {
    if (!window.confirm('Are you sure you want to delete this image?')) {
      return;
    }

    setDeletingImageId(imageId);
    setError(null);
    setSuccessMessage('');

    try {
      // Get token from session storage
      const token = sessionStorage.getItem('token');
      
      if (!token) {
        throw new Error('Authentication token not found. Please log in again.');
      }

      const response = await fetch(`http://localhost:5005/api/products/img/${imageId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error(`Failed to delete image: ${response.status}`);
      }

      // Update the product data by removing the deleted image
      setProductData(prevData => ({
        ...prevData,
        images: prevData.images.filter(img => img.productImageId !== imageId)
      }));
      
      setSuccessMessage('Image deleted successfully');
    } catch (err) {
      setError(`Error deleting image: ${err.message}`);
    } finally {
      setDeletingImageId(null);
    }
  };

  // Handle image upload success
  const handleImageUploadSuccess = (message) => {
    setSuccessMessage(message);
    setShowImageUpload(false);
    // Refetch product details to get updated images
    fetchProductDetails();
  };

  // Handle image upload error
  const handleImageUploadError = (errorMessage) => {
    setError(errorMessage);
  };

  // Add a new sub-product
  const handleAddSubProduct = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage('');
    setIsAddingSubProduct(true);

    try {
      // Get token from session storage
      const token = sessionStorage.getItem('token');
      
      if (!token) {
        throw new Error('Authentication token not found. Please log in again.');
      }

      const payload = {
        title: newSubProduct.title,
        price: parseFloat(newSubProduct.price),
        mainProductId: parseInt(id)
      };

      const response = await fetch('http://localhost:5005/api/products/sub', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error(`Failed to add sub-product: ${response.status}`);
      }

      //refresh
      fetchProductDetails();
      // Reset the form
      setNewSubProduct({ title: '', price: 0 });
      setSuccessMessage('Sub-product added successfully');
    } catch (err) {
      setError(`Error adding sub-product: ${err.message}`);
    } finally {
      setIsAddingSubProduct(false);
    }
  };

  // Delete/Restore sub-product
  const handleToggleSubProductStatus = async (subProductId, newStatus) => {
    const action = newStatus === 0 ? 'delete' : 'restore';
    if (newStatus === 0 && !window.confirm('Are you sure you want to delete this variant?')) {
      return;
    }

    setDeletingSubProductId(subProductId);
    setError(null);
    setSuccessMessage('');

    try {
      // Get token from session storage
      const token = sessionStorage.getItem('token');
      
      if (!token) {
        throw new Error('Authentication token not found. Please log in again.');
      }

      const response = await fetch(`http://localhost:5005/api/products/sub/${subProductId}?status=${newStatus}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error(`Failed to ${action} sub-product: ${response.status}`);
      }

      // Update the product data by updating the status of the sub-product
      setProductData(prevData => ({
        ...prevData,
        subProducts: prevData?.subProducts?.map(subProd => 
          subProd.subProductId === subProductId 
            ? { ...subProd, status: newStatus } 
            : subProd
        )
      }));
      
      setSuccessMessage(`Sub-product ${action}d successfully`);
    } catch (err) {
      setError(`Error ${action}ing sub-product: ${err.message}`);
    } finally {
      setDeletingSubProductId(null);
    }
  };

  // Format price
  const formatPrice = (price) => {
    return parseFloat(price).toLocaleString('en-US');
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:px-8 flex justify-between items-center">
          <div className="flex items-center">
            <button 
              onClick={() => navigate(-1)}
              className="mr-4 p-2 rounded-full hover:bg-gray-100"
            >
              <ArrowLeft className="h-5 w-5 text-gray-600" />
            </button>
            <h1 className="text-3xl font-bold text-gray-900">
              {loading ? 'Loading Product...' : `Edit Product: ${productData.productDetails.title}`}
            </h1>
          </div>
          <div className="flex items-center space-x-4">
            {productData.productDetails.status === 0 ? (
              <button
                onClick={handleRestoreProduct}
                disabled={saving}
                className="flex items-center px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700 disabled:opacity-50"
              >
                <RefreshCw className="mr-2 h-4 w-4" />
                Restore
              </button>
            ) : (
              <button
                onClick={handleDeleteProduct}
                disabled={saving}
                className="flex items-center px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700 disabled:opacity-50"
              >
                <Trash2 className="mr-2 h-4 w-4" />
                Delete
              </button>
            )}
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        ) : error ? (
          <div className="bg-red-50 p-4 rounded-md">
            <div className="flex">
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">Error</h3>
                <div className="mt-2 text-sm text-red-700">
                  <p>{error}</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="space-y-6">
            {/* Success Message */}
            {successMessage && (
              <div className="bg-green-50 p-4 rounded-md shadow">
                <div className="flex">
                  <div className="ml-3">
                    <p className="text-sm font-medium text-green-800">{successMessage}</p>
                  </div>
                </div>
              </div>
            )}
            
            {/* Product Images */}
            <div className="bg-white shadow rounded-lg overflow-hidden">
              <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
                <h2 className="text-lg font-medium text-gray-900">Product Images</h2>
                <button
                  onClick={() => setShowImageUpload(!showImageUpload)}
                  className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  {showImageUpload ? (
                    <>
                      <X className="mr-1 h-4 w-4" />
                      Cancel
                    </>
                  ) : (
                    <>
                      <Plus className="mr-1 h-4 w-4" />
                      Add Image
                    </>
                  )}
                </button>
              </div>
              
              {/* Image Upload Section */}
              {showImageUpload && (
                <div className="p-6 border-b border-gray-200">
                  <ImageUploadSection 
                    productId={id} 
                    onSuccess={handleImageUploadSuccess}
                    onError={handleImageUploadError}
                  />
                </div>
              )}
              
              <div className="p-6">
                {productData?.images?.length === 0 ? (
                  <div className="flex flex-col items-center justify-center p-6 text-center">
                    <Image className="h-12 w-12 text-gray-400 mb-2" />
                    <h3 className="text-sm font-medium text-gray-900">No images available</h3>
                    <p className="text-sm text-gray-500 mt-1">Upload images to showcase your product</p>
                  </div>
                ) : (
                  <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                    {productData?.images?.map((image) => (
                      <div key={image.productImageId} className="relative group">
                        <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-md bg-gray-200">
                          <img 
                            src={image.imageUrl} 
                            alt={`Product image ${image.productImageId}`}
                            className="h-full w-full object-cover object-center" 
                          />
                        </div>
                        <button
                          onClick={() => handleDeleteImage(image.productImageId)}
                          disabled={deletingImageId === image.productImageId}
                          className={`absolute top-2 right-2 p-1 rounded-full bg-red-600 text-white opacity-0 group-hover:opacity-100 transition-opacity ${
                            deletingImageId === image.productImageId ? 'opacity-50 cursor-not-allowed' : ''
                          }`}
                        >
                          {deletingImageId === image.productImageId ? (
                            <div className="h-4 w-4 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                          ) : (
                            <X className="h-4 w-4" />
                          )}
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            
            {/* Rest of the product edit form remains the same */}
            <div className="bg-white shadow rounded-lg overflow-hidden">
              <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
                <h2 className="text-lg font-medium text-gray-900">Product Details</h2>
                <div>
                  <span 
                    className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      productData.productDetails.status === 1
                        ? 'bg-green-100 text-green-800' 
                        : 'bg-red-100 text-red-800'
                    }`}
                  >
                    {productData.productDetails.status === 1 ? 'Active' : 'Inactive'}
                  </span>
                </div>
              </div>
              
              {/* Form */}
              <form onSubmit={handleSave}>
                <div className="px-6 py-4 space-y-6">
                  <div>
                    <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                      Product Title
                    </label>
                    <input
                      type="text"
                      name="title"
                      id="title"
                      value={productData.productDetails.title || ''}
                      onChange={handleChange}
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  </div>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label htmlFor="price" className="block text-sm font-medium text-gray-700 mb-1">
                        Price
                      </label>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <span className="text-gray-500 sm:text-sm"></span>
                        </div>
                        <input
                          type="number"
                          name="price"
                          id="price"
                          min="0"
                          step="0.01"
                          value={productData.productDetails.price || ''}
                          onChange={handleChange}
                          className="block w-full pl-7 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                          required
                        />
                      </div>
                    </div>
                    
                    <div>
                      <label htmlFor="discountPrice" className="block text-sm font-medium text-gray-700 mb-1">
                        Discount Price (Optional)
                      </label>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <span className="text-gray-500 sm:text-sm"></span>
                        </div>
                        <input
                          type="number"
                          name="discountPrice"
                          id="discountPrice"
                          min="0"
                          step="0.01"
                          value={productData.productDetails.discountPrice || ''}
                          onChange={handleChange}
                          className="block w-full pl-7 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        />
                      </div>
                    </div>
                  </div>
                  
                  <div>
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
                      Description
                    </label>
                    <textarea
                      name="description"
                      id="description"
                      rows="4"
                      value={productData.productDetails.description || ''}
                      onChange={handleChange}
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    ></textarea>
                  </div>
                </div>
                
                <div className="px-6 py-4 bg-gray-50 text-right">
                  <button
                    type="submit"
                    disabled={saving}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                  >
                    {saving ? (
                      <>
                        <div className="mr-2 h-4 w-4 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                        Saving...
                      </>
                    ) : (
                      <>
                        <Save className="mr-2 h-4 w-4" />
                        Save Changes
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
            
         {/* Sub Products Section */}
         <div className="bg-white shadow rounded-lg overflow-hidden">
              <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
                <h2 className="text-lg font-medium text-gray-900">Variants / Sub Products</h2>
              </div>
              
              {/* Add Sub Product Form */}
              {1 && (
                <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
                  <form onSubmit={handleAddSubProduct} className="space-y-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <label htmlFor="subProductTitle" className="block text-sm font-medium text-gray-700 mb-1">
                          Variant Title
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="subProductTitle"
                          value={newSubProduct.title}
                          onChange={handleSubProductChange}
                          className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                          required
                        />
                      </div>
                      <div>
                        <label htmlFor="subProductPrice" className="block text-sm font-medium text-gray-700 mb-1">
                          Variant Price
                        </label>
                        <div className="relative mt-1 rounded-md shadow-sm">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm"></span>
                          </div>
                          <input
                            type="number"
                            name="price"
                            id="subProductPrice"
                            min="0"
                            step="0.01"
                            value={newSubProduct.price}
                            onChange={handleSubProductChange}
                            className="block w-full pl-7 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end">
                      <button
                        type="button"
                        onClick={() => setIsAddingSubProduct(false)}
                        className="mr-3 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        disabled={isAddingSubProduct && !newSubProduct.title}
                        className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                      >
                        {isAddingSubProduct ? (
                          <>
                            <div className="mr-2 h-4 w-4 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                            Adding...
                          </>
                        ) : (
                          <>
                            <Plus className="mr-1 h-4 w-4" />
                            Add Variant
                          </>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              )}
              
              {/* Sub Products Table */}
              {productData?.subProducts && productData?.subProducts?.length > 0 ? (
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created At</th>
                        <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {productData?.subProducts?.map((subProduct) => (
                        <tr key={subProduct.subProductId}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{subProduct.title}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">LKR {formatPrice(subProduct.price)}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                              subProduct.status === 1 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                            }`}>
                              {subProduct.status === 1 ? 'Active' : 'Inactive'}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {new Date(subProduct.createdAt).toLocaleDateString()}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            {deletingSubProductId === subProduct.subProductId ? (
                              <span className="text-gray-400">
                                <div className="inline-block h-4 w-4 border-t-2 border-b-2 border-indigo-500 rounded-full animate-spin"></div>
                              </span>
                            ) : subProduct.status === 1 ? (
                              <button
                                onClick={() => handleToggleSubProductStatus(subProduct.subProductId, 0)}
                                className="text-red-600 hover:text-red-900"
                              >
                                Delete
                              </button>
                            ) : (
                              <button
                                onClick={() => handleToggleSubProductStatus(subProduct.subProductId, 1)}
                                className="text-green-600 hover:text-green-900"
                              >
                                Restore
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="p-6 text-center text-gray-500">
                  No variants found for this product. Add one to get started.
                </div>
              )}
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default ProductEditPage;