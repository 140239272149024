import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { useCart } from "../../Contexts/CartContext/CartContext";
import {
  RiArrowDropDownLine,
  RiCloseFill,
  RiCloseLargeFill,
  RiFacebookCircleFill,
  RiMenuFill,
  RiSearchLine,
  RiShoppingCartFill,
  RiUser3Fill,
  RiUser4Fill,
  RiUserCommunityFill,
  RiWhatsappFill,
} from "react-icons/ri";
import logo from "../../assets/logo.png";
import { fetchUserProfile } from "../../api/authApi";
import { toast } from "react-toastify";
import NavBarDropDown from "./NavBarDropDown";

function Navbar({ search, setSearch, user, setUser, isLogged, setIsLogged }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { cartItems } = useCart();
  const [scrolled, setScrolled] = useState(false); // New state for tracking scroll
  const navigate = useNavigate();

  // Toggle menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Add scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // In your Navbar component
  const handleClick = () => {
    navigate("/batchwise");
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = sessionStorage.getItem("token");
        if (!token) return;
        const data = await fetchUserProfile(token);

        setUser(data);
      } catch (err) {
        toast.error(err.message);
      }
    };

    fetchProfile();
  }, [isLogged]);

  return (
    <div
      className={`flex flex-col justify-center items-center w-full md:pt-3 fixed z-40 top-0 transition-all duration-300 ${
        scrolled ? "md:pt-0 bg-_white bg-opacity-5 " : ""
      }`}
    >
      <section
        className={`hidden lg:block w-[1024px] max-w-screen-lgx flex-col ${
          scrolled ? "scale-90 py-0" : ""
        }`}
      >
        <div
          className={`w-full flex flex-row justify-end translate-y-4 ${
            scrolled ? "hidden" : ""
          }`}
        >
          <div className="flex flex-row items-center justify-center gap-2 bg-_dark px-5 py-2.5 rounded-xl mr-4 text-_white shadow-md">
            <p className="text-xs mr-1">
              <span className="font-bold mr-2">Contact us : </span>
              <span className="hover:underline">doctorhub.lk@gmail.com</span>
            </p>
            <div className="flex flex-row items-center justify-center gap-2">
              <Link>
                <RiFacebookCircleFill />
              </Link>
              <Link>
                <RiWhatsappFill />
              </Link>
            </div>
          </div>
        </div>
        <div className="w-full bg-_white rounded-xl pt-5 shadow-md border border-0.5 border-_dark border-opacity-15">
          <div className=" flex flex-row justify-between mx-4">
            <div className="">
              <Link className="flex flex-col items-start" to={"/"}>
                {/* <h1><span className="font-black text-2xl uppercase text-_blue">Doctor</span><span className="font-medium text-2xl text-_dark_lite">Hub.lk</span></h1>
            <p className="text-[10px] font-semibold -translate-y-1">Your Trusted Partner in Medical Excellence</p> */}
                <img src={logo} alt="" />
              </Link>
            </div>
            <div className="flex flex-row  items-center justify-center gap-4">
              {/* <Link to={"#batchwise"}> */}
              <button
                onClick={handleClick}
                className="group flex flex-row items-center justify-center gap-2 text-_red border border-_red rounded-full px-3 py-1"
              >
                <RiUserCommunityFill className="text-2xl" />{" "}
                <p className="text-base font-semibold group-hover:underline ">
                  Batchwise purchases
                </p>
              </button>
              {/* </Link> */}
              <div className="flex flex-row items-center justify-center border border-_dark_lite px-3 py-1 rounded-full">
                <input
                  type="text"
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search item"
                  className=" focus:outline-none focus:ring-0 focus:border-transparent text-_gray w-64"
                />
                <RiSearchLine className="text-_bg_dark" />
              </div>
              <Link to={"/cart"} className="flex flex-row">
                <RiShoppingCartFill className="text-2xl text-_dark" />
                <div className="bg-_red rounded-full px-1 h-4 flex flex-row items-center justify-center -translate-x-2 -translate-y-1">
                  <p className="text-_white text-xs">{cartItems.length}</p>
                </div>
              </Link>
              <div className="flex flex-row items-center justify-center gap-1">
                <RiUser3Fill className="text-xl text-_dark" />
                <button className="group flex flex-col items-start">
                  <h1 className="text-[10px] text-_gray opacity-50">Welcome</h1>
                  <div className="text-sm font-semibold flex flex-row items-center justify-center -translate-y-1 text-_gray">
                    <p className="group-hover:underline">
                      {user ? user?.firstName : "Hello!"}
                    </p>
                    <RiArrowDropDownLine className="text-xl translate-y-0.5" />
                  </div>

                  <NavBarDropDown
                    user={user}
                    setUser={setUser}
                    isLogged={isLogged}
                    setIsLogged={setIsLogged}
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-row justify-end text-sm text-_dark_lite gap-8 pb-2.5 pt-1 pr-5">
            <Link to={"/"} className="hover:underline">
              Home
            </Link>
            <Link to={"/store"} className="hover:underline">
              Store
            </Link>
            <Link className="hover:underline" to={"store/tabs"}>
              Tabs
            </Link>
            <Link className="hover:underline" to={"store/tab-accessories"}>
              Tab Accessories
            </Link>
            <Link to={"/store/steth-accessories"} className="hover:underline">
              Steth Accessories
            </Link>
            <Link to={"/contact"} className="hover:underline">
              Contact
            </Link>
            <Link to={"/about"} className="hover:underline">
              About us
            </Link>
          </div>
        </div>
      </section>
      <section
        className={`hidden md:block lg:hidden w-full flex-col px-3 ${
          scrolled ? "scale-95 py-0" : ""
        }`}
      >
        <div
          className={`w-full flex flex-row justify-end translate-y-4 ${
            scrolled ? "hidden" : ""
          }`}
        >
          <div className="flex flex-row items-center justify-center gap-2 bg-_dark px-5 py-2.5 rounded-md mr-4 text-_white ">
            <p className="text-xs mr-1">
              <span className="font-bold mr-2">Contact us : </span>
              <span className="hover:underline">doctorhub.lk@gmail.com</span>
            </p>
            <div className="flex flex-row items-center justify-center gap-2">
              <Link>
                <RiFacebookCircleFill />
              </Link>
              <Link>
                <RiWhatsappFill />
              </Link>
            </div>
          </div>
        </div>
        <div className="w-full bg-_white rounded-md pt-5 shadow-sm border border-0.5 border-_dark border-opacity-15">
          <div className=" flex flex-row justify-between mx-2">
            <div className="">
              <Link className="flex flex-col items-start">
                {/* <h1><span className="font-black text-2xl uppercase text-_blue">Doctor</span><span className="font-medium text-2xl text-_dark_lite">Hub.lk</span></h1>
            <p className="text-[10px] font-semibold -translate-y-1 text-start">Your Trusted Partner in Medical Excellence</p> */}
                <img src={logo} alt="" />
              </Link>
            </div>
            <div className="flex flex-row  items-center justify-center gap-4">
              <Link>
                <button className="group flex flex-row items-center justify-center gap-1 h-8 text-_red border border-_red rounded-full px-2 py-1">
                  <RiUserCommunityFill className="text-3xl" />
                  <p className="text-xs font-semibold group-hover:underline  flex flex-col items-start -translate-y-1">
                    <p className="text-[10px] translate-y-1 ">Batchwise</p>
                    <p>purchases</p>
                  </p>
                </button>
              </Link>
              <div className="flex flex-row items-center justify-center border border-_dark_lite px-3 py-1 rounded-full">
                <input
                  type="text"
                  placeholder="Search item"
                  onChange={(e) => setSearch(e.target.value)}
                  className=" focus:outline-none focus:ring-0 focus:border-transparent text-_gray w-full"
                />
                <RiSearchLine className="text-_bg_dark" />
              </div>
              <Link to={"/cart"} className="flex flex-row">
                <RiShoppingCartFill className="text-2xl text-_dark" />
                <div className="bg-_red rounded-full px-1 h-4 flex flex-row items-center justify-center -translate-x-2 -translate-y-1">
                  <p className="text-_white text-xs">{cartItems.length}</p>
                </div>
              </Link>
              <div className="flex flex-row items-center justify-center gap-1">
                <RiUser3Fill className="text-xl text-_dark" />
                <button className="group flex flex-col items-start">
                  <h1 className="text-[10px] text-_gray opacity-50">Welcome</h1>
                  <div className="text-sm font-semibold flex flex-row items-center justify-center -translate-y-1 text-_gray">
                    <p className="group-hover:underline">
                      {user && user?.firstName || "Hello!"}
                    </p>
                    <RiArrowDropDownLine className="text-xl translate-y-0.5" />
                  </div>

                  <NavBarDropDown
                    user={user}
                    setUser={setUser}
                    isLogged={isLogged}
                    setIsLogged={setIsLogged}
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-row justify-end text-sm text-_dark_lite gap-8 pb-2.5 pt-1 pr-5">
            <Link className="hover:underline" to={"/"}>
              Home
            </Link>
            <Link className="hover:underline" to={"/store"}>
              Store
            </Link>
            <Link className="hover:underline" to={"store/tabs"}>
              Tabs
            </Link>
            <Link className="hover:underline" to={"store/tab-accessories"}>
              Tab Accessories
            </Link>
            <Link className="hover:underline" to={"store/steth-accessories"}>
              Steth Accessories
            </Link>
            <Link className="hover:underline" to={"contact"}>
              Contact
            </Link>
            <Link className="hover:underline" to={"about"}>
              About us
            </Link>
          </div>
        </div>
      </section>

      {/* Mobile ---------------------------------------------------------------------------- */}

      <section
        className={`md:hidden w-full flex flex-col ${
          scrolled ? "scale-98 py-0" : ""
        }`}
      >
        <div
          className={`w-full flex flex-row justify-end ${
            scrolled ? "hidden" : ""
          }`}
        >
          <div className="flex flex-row items-center justify-center gap-2 bg-_dark px-5 py-1 sm:py-2.5 w-full text-_white ">
            <p className="text-xs mr-1">
              <span className="font-bold mr-2 hidden sm:block">
                Contact us :{" "}
              </span>
              <span className="hover:underline">
              doctorhub.lk@gmail.com
              </span>
            </p>
            <div className="flex flex-row items-center justify-center gap-2">
              <Link>
                <RiFacebookCircleFill />
              </Link>
              <Link>
                <RiWhatsappFill />
              </Link>
            </div>
          </div>
        </div>
        <div className="w-full bg-_white pt-2  shadow-sm border border-0.5 border-_dark border-opacity-15">
          <div className=" flex flex-row justify-between items-center mx-2 py-1">
            <div className="">
              <Link className="flex flex-col items-start" to={"/"}>
                {/* <h1><span className="font-black text-2xl uppercase text-_blue">Doctor</span><span className="font-medium text-2xl text-_dark_lite">Hub.lk</span></h1>
            <p className="text-[10px] font-semibold -translate-y-1 text-start">Your Trusted Partner in Medical Excellence</p> */}
                <img
                  src={logo}
                  alt=""
                  className=" h- sm:h-auto w-32 sm:w-36 "
                />
              </Link>
            </div>
            <div className="flex flex-row  items-center justify-center gap-2">
              <Link to={"/cart"} className="flex flex-row">
                <RiShoppingCartFill className="text-xl text-_dark" />
                <div className="bg-_red rounded-full px-1 h-3 flex flex-row items-center justify-center -translate-x-2 -translate-y-1">
                  <p className="text-_white text-xs">{cartItems.length}</p>
                </div>
              </Link>
              <div className="flex flex-row items-center justify-center gap-1">
                <RiUser3Fill className="text-2xl text-_dark pb-1" />
                <button className="group flex flex-col items-start">
                  <h1 className="text-[10px] text-_gray opacity-50">Welcome</h1>
                  <div className="text-xs font-bold flex flex-row items-center justify-center -translate-y-1 text-_gray">
                    <p className="group-hover:underline ">
                      {user && user?.firstName || "Hello!"}
                    </p>
                    <RiArrowDropDownLine className="text-xl translate-y-0.5" />
                  </div>

                  <NavBarDropDown
                    user={user}
                    setUser={setUser}
                    isLogged={isLogged}
                    setIsLogged={setIsLogged}
                  />
                </button>
              </div>
              <button className="" onClick={toggleMenu}>
                {isOpen ? (
                  <RiCloseLargeFill size={28} />
                ) : (
                  <RiMenuFill size={28} />
                )}
              </button>
            </div>
          </div>
          <div>
            <div
              className={` w-full   overflow-hidden transition-all duration-300 ${
                isOpen ? "max-h-72 opacity-100" : "max-h-0 opacity-0"
              }`}
            >
              <div className="w-full flex  flex-col items-center justify-center gap-4 mt-2">
                <hr className=" w-full" />

                <div className="flex flex-row items-center justify-center border border-_dark_lite px-3 py-1 rounded-full w-4/5 ">
                  <input
                    type="text"
                    placeholder="Search item"
                    onChange={(e) => setSearch(e.target.value)}
                    className=" focus:outline-none focus:ring-0 focus:border-transparent text-_gray w-full"
                  />
                  <RiSearchLine className="text-_bg_dark" />
                </div>
                <hr className="mt- w-4/5" />
                {search == "" && (
                  <div className="w-full flex flex-col items-start px-8  justify-end text-sm text-_dark_lite gap-3 pb-4 pt- pr-5">
                    <Link
                      className="hover:underline w-full text-start"
                      to={"/"}
                    >
                      Home
                    </Link>
                    <Link
                      className="hover:underline w-full text-start"
                      to={"/store"}
                    >
                      Store
                    </Link>
                    <Link
                      className="hover:underline w-full text-start"
                      to={"store/tab-accessories"}
                    >
                      Tab Accessories
                    </Link>
                    <Link
                      className="hover:underline w-full text-start"
                      to={"store/tabs"}
                    >
                      Tabs
                    </Link>
                    <Link
                      className="hover:underline w-full text-start"
                      to={"store/steth-accessories"}
                    >
                      Steth Accessories
                    </Link>
                    <Link
                      className="hover:underline w-full text-start"
                      to={"contact"}
                    >
                      Contact
                    </Link>
                    <Link
                      className="hover:underline w-full text-start"
                      to={"about"}
                    >
                      About us
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Navbar;
