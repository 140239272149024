import { useState } from "react";

const ProductImage = ({ imgs }) => {
  const [selectedImage, setSelectedImage] = useState(imgs[0]);
  
  return (
    <div className="flex flex-col-reverse xl:flex-row items-start gap-4 w-full">
      {/* Image Gallery */}
      <div className="flex flex-row xl:flex-col gap-4 xl:w-30">
        {imgs.map((img, index) => (
          <img
            key={index}
            src={img}
            alt={`Thumbnail ${index + 1}`}
            className={` aspect-square xl:w-24x xl:h-20 size-12 sm:size-16 md:size-16 lg:size-24 object-cover  cursor-pointer hover:scale-105 shadow-md border border-_gray border-opacity-10 ${
              selectedImage === img ? "border-black" : ""
            }`}
            onClick={() => setSelectedImage(img)}
          />
        ))}
      </div>

      {/* Main Image with Magnification */}
      <div className=" aspect-square w-full relative overflow-hidden shadow-md border border-_gray border-opacity-20">
        <img
          src={selectedImage}
          alt="Selected"
          className=" aspect-square w-full object-cover cursor-crosshair transition-transform duration-300 hover:scale-150"
        />
      </div>
    </div>
  );
};

export default ProductImage