import React, { useState } from "react";
import { startCashPayment } from "../../api/productApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useCart } from "../../Contexts/CartContext/CartContext";
import ConfirmationModal from "./Confirmation";

const CashOnDelivery = ({ shippingDetails }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showFirstConfirm, setShowFirstConfirm] = useState(false);
  const [showSecondConfirm, setShowSecondConfirm] = useState(false);
  const navigate = useNavigate();
  const { clearCart } = useCart();

  const initiatePayment = () => {
    setShowFirstConfirm(true);
  };

  const handleFirstConfirm = () => {
    setShowFirstConfirm(false);
    setShowSecondConfirm(true);
  };

  const handleSecondConfirm = async () => {
    setShowSecondConfirm(false);
    setIsLoading(true);

    if (!shippingDetails) {
      console.error("User or shipping details are missing");
      setIsLoading(false);
      return;
    }

    const paymentDetails = {
      phone: shippingDetails.phone || "N/A",
      address: shippingDetails.address || "N/A",
      city: shippingDetails.city || "N/A",
    };

    try {
      // Get cart items from localStorage
      const storedCart = JSON.parse(localStorage.getItem("cart")) || [];

      if (storedCart.length === 0) {
        console.error("Cart is empty");
        toast.error("Your Cart is emtey!");
        setIsLoading(false);
        return;
      }

      // Call API
      const response = await startCashPayment(paymentDetails, storedCart);

      if (response.status === 200) {
        navigate("/profile");
        clearCart();
        toast.success("Order Placed Successfully");
      } else {
        toast.error("Unknown Error. Please try again later!");

      }
    } catch (error) {
      console.error("Payment failed:", error);
      toast.error("Payment failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setShowFirstConfirm(false);
    setShowSecondConfirm(false);
  };

  return (
    <div>
      {/* Main Cash on Delivery Button */}
      <button
        disabled={isLoading}
        onClick={initiatePayment}
        className="border bg-myRed py-2 px-4 text-_dark font-semibold rounded-lg hover:bg-_gray_hover"
      >
        {!isLoading ? "Cash on Delivery" : "Processing"}
      </button>

      {/* First Confirmation Dialog using the new component */}
      <ConfirmationModal
        isOpen={showFirstConfirm}
        onClose={handleCancel}
        onConfirm={handleFirstConfirm}
        title="Confirm Order Details"
        message="Please confirm that the following details are correct:"
        confirmText="Continue"
      >
        <div>
          <p><strong>Phone:</strong> {shippingDetails?.phone || "N/A"}</p>
          <p><strong>Address:</strong> {shippingDetails?.address || "N/A"}</p>
          <p><strong>City:</strong> {shippingDetails?.city || "N/A"}</p>
        </div>
      </ConfirmationModal>

      {/* Second Confirmation Dialog using the new component */}
      <ConfirmationModal
        isOpen={showSecondConfirm}
        onClose={handleCancel}
        onConfirm={handleSecondConfirm}
        title="Final Confirmation"
        message="Are you sure you want to place this order with Cash on Delivery?"
        confirmText="Confirm Order"
      />
    </div>
  );
};

export default CashOnDelivery;