import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CartLoadingSkeleton = ({ itemCount = 3 }) => {
  return (
    <div className="max-w-screen-lg mx-8 lg:mx-auto mt-36 md:mt-48">
      {/* Cart title skeleton */}
      <div className="mb-8">
        <Skeleton height={36} width={150} />
      </div>
      
      {/* Cart items */}
      {[...Array(itemCount)].map((_, index) => (
        <div key={index} className="flex flex-col md:flex-row items-center justify-start md:justify-between border-b py-4 gap-4 md:gap-0">
          {/* Product info */}
          <div className="w-full md:w-auto flex flex-col items-start">
            <div className="mb-1">
              <Skeleton height={20} width={200} />
            </div>
            <div className="mb-1">
              <Skeleton height={14} width={150} />
            </div>
            <div>
              <Skeleton height={18} width={80} />
            </div>
          </div>
          
          {/* Quantity controls and remove button */}
          <div className="w-full md:w-auto flex items-center justify-end gap-4">
            <Skeleton height={32} width={32} borderRadius={4} />
            <Skeleton height={24} width={20} />
            <Skeleton height={32} width={32} borderRadius={4} />
            <Skeleton height={40} width={80} borderRadius={4} />
          </div>
        </div>
      ))}
      
      {/* Total and checkout button */}
      <div className="text-right mt-6">
        <div className="mb-2">
          <Skeleton height={28} width={120} className="ml-auto" />
        </div>
        <div>
          <Skeleton height={40} width={180} className="ml-auto" borderRadius={8} />
        </div>
      </div>
    </div>
  );
};

export default CartLoadingSkeleton;