import React from "react";

const SortCard = ({ sort, setSort }) => {
  const handleSortChnage = (e) => {
    setSort(e.target.value);
  };
  return (
    <div className="flex flex-row items-center justify-center  border px-2 rounded-md text-xs md:text-sm">
      <h2 className="text-_gray w-14">Sort By :</h2>
      <div className="w-28 md:w-40">
        <select
          value={sort}
          onChange={handleSortChnage}
          className="block text-xs md:text-base  w-full text-_gray py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-none"
        >
          <option value="1" className="hover:bg-blue-100  hover:text-_white">
            Best Match
          </option>
          <option value="2">Lowest to High</option>
          <option value="3">High to Lowest</option>
        </select>
      </div>
    </div>
  );
};

export default SortCard;
