import React from "react";
import { useNavigate, Link } from "react-router-dom";

const NavBarDropDown = ({user,setUser, isLogged,setIsLogged}) => {
    const navigate = useNavigate();
  const handleLogout = () => {
    sessionStorage.removeItem("token");
    setUser(null);
    setIsLogged(false);
    navigate("/auth/login");
  };

  return (
    <>
      <div className=" hidden group-hover:block bg-_white absolute right-1 xl:right-auto w-56 translate-y-8 shadow-md border rounded-lg py-4 px-4">
        <div className="w-full flex flex-col items-center justify-center text-_dark gap-4">
          <div className="flex flex-col items-center gap-1">
            <img
              src={user && user?.profileImg}
              alt=""
              className=" rounded-full"
              width={40}
              referrerPolicy="no-referrer"
            />

            <h2 className="text-sm font-semibold">
              {user && user?.firstName + " " + user?.lastName}{" "}
            </h2>
            <p className="text-xs text-_gray">{user && user?.email}</p>
          </div>
          <div className="w-full flex flex-col items-center text-xl text-_dark  gap-2">
            <Link to={"/profile"} className="w-full hover:bg-_gray_hover">
              Profile
            </Link>
            <hr className="w-full h-[0.5px] bg-_gray_lite" />
            <Link to={"/cart"} className="w-full hover:bg-_gray_hover">
              Cart
            </Link>
            <hr className="w-full h-[0.5px] bg-_gray_lite" />
          </div>

          {!isLogged ? (
            <div className="grid grid-cols-2 gap-2 font-semibold">
              <Link
                to={"/auth/login"}
                className="col-span-1 border rounded-md hover:bg-_gray_hover"
              >
                <button className="  text-_dark   px-4 py-1">Login</button>
              </Link>
              <Link
                to={"/auth/register"}
                className="col-span-1 border bg-_gray hover:bg-opacity-90 rounded-md"
              >
                <button className=" w-full  text-_white   px-4 py-1   ">
                  Register
                </button>
              </Link>
            </div>
          ) : (
            <div>
              <button onClick={handleLogout}>Log out</button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NavBarDropDown;
