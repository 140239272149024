const NoItemsFound = () => {
    return (
      <div className="flex flex-col items-center justify-center text-center py-10 h-72 ">
        {/* <img
          src="/images/no-items.png"
          alt="No Items Found"
          className="w-32 h-32 mb-4"
        /> */}
        <h2 className="text-lg font-semibold text-gray-700">No Items Found</h2>
        <p className="text-gray-500">Try adjusting your filters or browse other categories.</p>
      </div>
    );
  };
  
  export default NoItemsFound;
  