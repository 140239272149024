import React, { useState, useEffect } from "react";
import { CircleUser } from "lucide-react";
import { Link } from "react-router-dom";

const LatestOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({
    totalOrders: 0,
    totalPages: 0,
    currentPage: 1,
  });

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true);

        // Get current date for endDate
        const endDate = new Date();
        endDate.setDate(endDate.getDate() + 1);
        const formattedEndDate = endDate.toISOString().split("T")[0];

        // Get date from one month ago for startDate
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        const formattedStartDate = startDate.toISOString().split("T")[0];

        // Get token from session storage
        const token = sessionStorage.getItem("token");

        if (!token) {
          throw new Error("Authentication token not found");
        }

        // Make API request
        const response = await fetch(
          `http://localhost:5005/api/orders?startDate=${formattedStartDate}&endDate=${formattedEndDate}&page=1&limit=10`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch orders");
        }

        const data = await response.json();

        // Extract orders array from the response
        setOrders(data.orders || []);

        // Set pagination data
        setPagination({
          totalOrders: data.totalOrders || 0,
          totalPages: data.totalPages || 0,
          currentPage: data.currentPage || 1,
        });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  if (loading) {
    return (
      <div className="p-4 rounded-lg bg-_white text-black">
        <h2 className="text-xl font-bold mb-4">Latest Orders</h2>
        <div className="flex justify-center">
          <p>Loading orders...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 rounded-lg bg-white text-black">
        <h2 className="text-xl font-bold mb-4">Latest Orders</h2>
        <div className="flex justify-center text-red-400">
          <p>Error: {error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4 rounded-lg bg-white text-black">
      <h2 className="text-xl font-bold mb-4">Latest Orders</h2>

      <div className="space-y-4 max-h-96 overflow-y-auto pr-2">
        {orders.map((order) => (
          <div
            key={order.orderId}
            className="flex items-center justify-between"
          >
            <div className="flex items-center gap-3">
              <div className="bg-white  rounded-full w-10 h-10 flex items-center justify-center">
                {/* <CircleUser className="text-gray-400" size={24} /> */}
                <img
                  className="rounded-full"
                  src={order.profileImg}
                  alt="dp"
                  referrerPolicy="no-referrer"
                />
              </div>
              <div>
                <p className="font-medium">{`${order.firstName} ${order.lastName}`}</p>
                <p className="text-sm text-gray-400">{order.phone}</p>
              </div>
            </div>
            <div className="font-medium text-right">
              <div>LKR {parseFloat(order.amount).toFixed(2)}</div>
              <div className="text-xs text-gray-400">{order.paymentStatus}</div>
            </div>
          </div>
        ))}

        {orders.length === 0 && (
          <div className="text-center py-4 text-gray-400">
            No orders found in the selected date range
          </div>
        )}
      </div>

      {pagination.totalOrders > 0 && (
        <div className="mt-4 text-sm text-gray-400 text-right">
          Showing {orders.length} of {pagination.totalOrders} orders • Page{" "}
          {pagination.currentPage} of {pagination.totalPages}
          <br />
          <Link to={"/admin/orders"}>see all</Link>
        </div>
      )}
    </div>
  );
};

export default LatestOrders;
