import React, { useState } from 'react';
import PropTypes from 'prop-types';
import StarRating from './AddStartRating';
import { submitReview } from '../../api/productApi';

const AddReview = ({ mainProductId, isLogged }) => {
  const [rating, setRating] = useState(5);
  const [comment, setComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!comment.trim()) {
      setError('Please add a comment');
      return;
    }
  
    setIsSubmitting(true);
    setError(null);
  
    try {
      const data = await submitReview({ mainProductId, rating, comment });
  
      setSuccess(true);
      setComment('');
      setRating(5);
  
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  // This function now directly receives the star value (1-5)
  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  return (
    <div className="my-8 p-6 bg-white rounded-lg shadow-sm border border-_gray">
      <h2 className="text-_dark text-xl font-semibold mb-4">Write a Review</h2>
      
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-_dark mb-2">Your Rating</label>
          <div className="flex items-center">
            <StarRating 
              rating={rating} 
              editable={true} 
              onChange={handleRatingChange} 
            />
            <span className="ml-2 text-_dark">{rating}</span>
          </div>
        </div>
        
        <div className="mb-4">
          <label htmlFor="comment" className="block text-_dark mb-2">
            Your Review
          </label>
          <textarea
            id="comment"
            className="w-full px-3 py-2 border border-_gray rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows="4"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Share your experience with this product..."
            disabled={isSubmitting}
          />
        </div>
        
        {error && (
          <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-md">
            {error}
          </div>
        )}
        
        {success && (
          <div className="mb-4 p-3 bg-green-100 text-green-700 rounded-md">
            Your review has been submitted successfully!
          </div>
        )}
        
        <button
          type="submit"
          className="px-4 py-2 bg-_dark text-white rounded-md hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Submit Review'}
        </button>
      </form>
    </div>
  );
};

AddReview.propTypes = {
  mainProductId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default AddReview;