import { useState, useEffect, useRef, useCallback } from "react";
import { categories } from "./Data";
import ItemCard from "../../components/ItemCard/ItemCard";
import StoreCategories from "../../components/StoreCategories";
import StorePriceFilter from "./StorePriceFilter";
import StoreFloatingFilter from "./StoreFloatingFilter";
import SortCard from "./SortCard";
import LoadingSkeleton from "./ProductSkeleton ";
import NoItemsFound from "./NoItemFound";
import { Link } from "react-router-dom";
import { fetchProducts } from "../../api/productApi";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

function Store({ search }) {
  // API dependencies
  const [categoryId, setCategoryId] = useState(null);
  const [page, setPage] = useState(1);
  const limit = 12;
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [sort, setSort] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  // Products
  const [products, setProducts] = useState({ products: [], totalProducts: 0, totalPages: 0 });
  const [allLoadedProducts, setAllLoadedProducts] = useState([]);

  // Ref for intersection observer
  const observer = useRef();
  const loadingRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );

  // Fetch products on initial load and filter changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        // Reset products when filters change
        if (page === 1) {
          setAllLoadedProducts([]);
        }
        
        const response = await fetchProducts(
          categoryId,
          page,
          limit,
          minPrice,
          maxPrice,
          sort,
          search
        );
        
        if (page === 1) {
          setAllLoadedProducts(response.products || []);
        } else {
          setAllLoadedProducts((prev) => [...prev, ...(response.products || [])]);
        }
        
        setProducts(response);
        setHasMore(response.totalPages > page);
      } catch (error) {
        console.error("Error fetching products:", error);
        alert(error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchData();
  }, [page, categoryId, minPrice, maxPrice, sort, search]);
  
  // Reset page when filters change
  useEffect(() => {
    setPage(1);
  }, [categoryId, minPrice, maxPrice, sort, search]);

  // Responsive loading skeleton based on viewport
  const LoadingSkeletonRow = () => {
    return (
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full">
        <LoadingSkeleton count={1} />
        <LoadingSkeleton count={1} />
        <div className="hidden md:block">
          <LoadingSkeleton count={1} />
        </div>
        <div className="hidden lg:block">
          <LoadingSkeleton count={1} />
        </div>
      </div>
    );
  };

  return (
    <div className="mb-20 mt-28 sm:mt-36 md:mt-48 w-full flex flex-col items-start text-_dark">
      <div className="w-full flex flex-row items-center justify-center text-_dark">
        <div className="lg:max-w-screen-xl mx-4 sm:mx-8 lg:mx-8 xl:mx-4 grid grid-cols-12">
          {/* left column */}
          <div className="col-span-12 md:col-start-3 sm:pl-8 md:col-end-13 mb-4">
            <Breadcrumb />
          </div>
          <section className="hidden md:block md:col-span-2 clear-start text-_dark">
            <StoreCategories
              categories={categories}
              categoryId={categoryId}
              setCategoryId={setCategoryId}
              setPage={setPage}
            />
            <hr className="my-8" />
            <div>
              <StorePriceFilter
                setPage={setPage}
                minPrice={minPrice}
                setMinPrice={setMinPrice}
                maxPrice={maxPrice}
                setMaxPrice={setMaxPrice}
              />
            </div>
          </section>
          <StoreFloatingFilter
            categoryId={categoryId}
            setCategoryId={setCategoryId}
            setPage={setPage}
            minPrice={minPrice}
            setMinPrice={setMinPrice}
            maxPrice={maxPrice}
            setMaxPrice={setMaxPrice}
          />
          {/* right column */}
          <section className="col-span-12 md:col-span-10 text-_dark sm:pl-8">
            <div>
              <h1 className="text-center sm:text-start text-sm font-bold">
                {products?.totalProducts} results for{" "}
                {categories.find((category) => category.id === categoryId)?.name}
              </h1>
              <div className="flex flex-col sm:flex-row justify-between items-end sm:items-center">
                <p className="text-center sm:text-start text-xl sm:text-base w-full mb-3 sm:mb-0">
                  Results for "
                  {
                    categories.find((category) => category.id === categoryId)
                      ?.name
                  }
                  "
                </p>

                <SortCard sort={sort} setSort={setSort} />
              </div>
            </div>

            {/* Initial loading - full page skeleton */}
            {isLoading && page === 1 && (
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-8">
                <LoadingSkeleton count={4} />
                <LoadingSkeleton count={4} />
                <div className="hidden md:block">
                  <LoadingSkeleton count={4} />
                </div>
                <div className="hidden lg:block">
                  <LoadingSkeleton count={4} />
                </div>
              </div>
            )}

            {/* display products */}
            {(!isLoading || page > 1) && (
              <div
                className={`grid ${
                  allLoadedProducts.length === 0
                    ? "grid-cols-1"
                    : "grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
                } gap-4 mt-8 auto-rows-fr`}
              >
                {allLoadedProducts.length === 0 && !isLoading ? (
                  <NoItemsFound />
                ) : (
                  allLoadedProducts.map((product) => (
                    <Link
                      to={`/product/${product.mainProductId}`}
                      key={product.mainProductId}
                      className=""
                    >
                      <ItemCard product={product} />
                    </Link>
                  ))
                )}
              </div>
            )}

            {/* Loading more indicator - appears at bottom when scrolling */}
            {isLoading && page > 1 && (
              <div className="mt-8 w-full">
                <LoadingSkeletonRow />
              </div>
            )}
            
            {/* Intersection observer target */}
            {!isLoading && hasMore && allLoadedProducts.length > 0 && (
              <div ref={loadingRef} className="h-8 w-full" />
            )}
            
            {/* No more products message */}
            {!hasMore && allLoadedProducts.length > 0 && (
              <div className="text-center py-8 text-gray-500">
                You have reached to the End!
              </div>
            )}
          </section>
        </div>
      </div>
    </div>
  );
}

export default Store;