import axios from "axios";
const baseURL = process.env.REACT_APP_API_BASE_URL;

export const fetchProductById = async (id) => {
  try {
    const response = await axios.get(`${baseURL}/api/products/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching product:", error);
    return null;
  }
};

export const fetchReviews = async (productId) => {
  try {
    const response = await axios.get(`${baseURL}/api/reviews/main/product/${productId}`);
    return response.data; // Assuming API returns an array of reviews
  } catch (error) {
    console.error("Error fetching reviews:", error);
    throw error;
  }
};

export const submitReview = async ({ mainProductId, rating, comment }) => {
  try {
    const token = sessionStorage.getItem('token');
    if (!token) throw new Error('Unauthorized: No token found');

    const response = await fetch(`${baseURL}/api/reviews/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ mainProductId, rating, comment }),
    });

    if (!response.ok) {
      throw new Error('Failed to submit review');
    }

    return await response.json();
  } catch (error) {
    throw new Error(error.message || 'Something went wrong');
  }
};

export const fetchProducts = async (categoryId = null, page = 1, limit = 10, minPrice, maxPrice, sort, search) => {
  try {
    const response = await axios.get(`${baseURL}/api/products`, {
      params: { categoryId, page, limit, minPrice, maxPrice, sort, search },
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Failed to fetch products:", error);
    return null;
  }
};

export const fetchCartProducts = async () => {
  try {
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];

    if (storedCart.length === 0) {
      return [];
    }

    const response = await axios.post(`${baseURL}/api/products/cart`,  { cartItems: storedCart }, {
      headers: { "Content-Type": "application/json" },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching cart:", error);
    return null;
  }
};

export const startPayment = async (paymentDetails, cartItems) => {
  try {
    const token = sessionStorage.getItem("token"); // Retrieve the auth token from localStorage
    const response = await axios.post(`${baseURL}/api/orders/online/payment/start`, {
      paymentDetails,
      cartItems,
    }, {
      headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add the auth token to the Authorization header
      },
    });

    return response.data; // API response (hash, merchant_id, amount, order_id, currency)
  } catch (error) {
    console.error("Payment API Error:", error.response?.data || error.message);
    throw error;
  }
};

export const startCashPayment = async (paymentDetails, cartItems) => {
  try {
    const token = sessionStorage.getItem("token"); // Retrieve the auth token from localStorage
    const response = await axios.post(`${baseURL}/api/orders/cash`, {
      paymentDetails,
      cartItems,
    }, {
      headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add the auth token to the Authorization header
      },
    });

    return response.data; // API response (hash, merchant_id, amount, order_id, currency)
  } catch (error) {
    console.error("Payment API Error:", error.response?.data || error.message);
    throw error;
  }
};