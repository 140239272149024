import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const LoadingSkeleton = ({ count = 12 }) => {
  return (
    
    <>
      {[...Array(count)].map((_, index) => (
     <div className="w-full">
      <div className=" hidden md:block">
      <div key={index} className="bg-white p-4 rounded-lg shadow-sm w-full ">
          {/* Image */}
          <Skeleton height={180} borderRadius={10} />

          {/* Title */}
          <div className="mt-2">
            <Skeleton height={15} width="80%" />
          </div>

          {/* Rating (stars placeholder) */}
          <div className="mt-1">
            <Skeleton height={12} width="50%" />
          </div>

          {/* Price */}
          <div className="mt-2">
            <Skeleton height={20} width="60%" />
          </div>

          {/* Button */}
          <div className="mt-4">
            <Skeleton height={30} width="80%" borderRadius={8} />
          </div>
        </div>
      </div>
      <div className=" md:hidden w-full grid grid-cols-2">
      <div key={index} className="bg-white w-40 xxs:w-48 sm:w-52  pr-4 rounded-lg shadow-sm  ">
          {/* Image */}
          <Skeleton height={180}   borderRadius={10} width="90%"/>

          {/* Title */}
          <div className="mt-2">
            <Skeleton height={15} width="80%" />
          </div>

          {/* Rating (stars placeholder) */}
          <div className="mt-1">
            <Skeleton height={12} width="50%" />
          </div>

          {/* Price */}
          <div className="mt-2">
            <Skeleton height={20} width="60%" />
          </div>

          {/* Button */}
          <div className="mt-4">
            <Skeleton height={30} width="80%" borderRadius={8} />
          </div>
        </div>
      </div>
     </div>
      ))}
    </>
  );
};

export default LoadingSkeleton;
