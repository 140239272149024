// src/api/statsApi.js
import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const getStats = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/stats/flash`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`, // Pass JWT token in headers
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching stats:', error);
    throw error;  // Rethrow error to handle it in the component
  }
};

export const fetchMonthlyIncome = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/stats/income/by/month`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching income data:", error);
    return null;
  }
};

