import React from 'react';
import { BsArrowUpRightCircle } from 'react-icons/bs';
import { RiArrowRightUpLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';

const SubCategoryCard = ({ placeholderImg, title, paragraph, path }) => {
  return (
    <div className="h-full">
      <Link to={path} className="block h-full">
        <div 
          className="bg-_white border border-_gray_lite rounded-lg p-6 relative overflow-hidden transition-all duration-300 h-full
                    shadow-sm hover:shadow-lg group"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-duration="400"
        >
          {/* Arrow icon with smoother animation */}
          <div className="absolute top-5 right-5 z-10 transition-transform duration-300 group-hover:translate-x-1 group-hover:-translate-y-1">
            <BsArrowUpRightCircle className="text-_blue text-2xl transition-all" />
          </div>
          
          {/* Content container */}
          <div className="relative h-full flex flex-col">
            {/* Image container with consistent sizing */}
            <div className="mb-4 flex items-center justify-center transition-all duration-300 group-hover:opacity-80">
              <img 
                src={placeholderImg} 
                alt={title}
                className="aspect-[4/3] h-[180px] w-auto object-contain"
              />
            </div>
            
            {/* Text content with better typography */}
            <div className="mt-auto transition-all duration-300">
              <h3 className="text-2xl font-semibold text-_blue mb-2">{title}</h3>
              <p className="text-_gray text-sm leading-relaxed">
                {paragraph}
              </p>
            </div>
            
            {/* Visit overlay - improved with better positioning and transition */}
            <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-all duration-300 bg-_white bg-opacity-0 group-hover:bg-opacity-90">
              <div className="transform translate-y-4 group-hover:translate-y-0 transition-all duration-300 
                          flex items-center gap-2 text-_blue font-medium px-5 py-3 rounded-md border border-_blue">
                <span>View Details</span>
                <RiArrowRightUpLine className="text-lg" />
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default SubCategoryCard;