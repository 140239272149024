// This would go in your StarRating.jsx file
import React from 'react';
import PropTypes from 'prop-types';

const AddStarRating = ({ rating, editable, onChange }) => {
  const stars = [1, 2, 3, 4, 5];
  
  const handleStarClick = (starValue) => {
    if (editable && onChange) {
      onChange(starValue);
    }
  };

  return (
    <div className="flex text-yellow-400">
      {stars.map((star) => (
        <span
          key={star}
          className={`cursor-${editable ? 'pointer' : 'default'} text-2xl`}
          onClick={() => handleStarClick(star)}
        >
          {star <= rating ? '★' : '☆'}
        </span>
      ))}
    </div>
  );
};

AddStarRating.propTypes = {
  rating: PropTypes.number.isRequired,
  editable: PropTypes.bool,
  onChange: PropTypes.func
};

AddStarRating.defaultProps = {
  editable: false
};

export default AddStarRating;