import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useTheme } from "../../hooks/use-theme";

import { overviewData, recentSalesData, topProducts } from "../../constants";

import { Footer } from "../../layouts/footer";

import {
  CreditCard,
  DollarSign,
  Package,
  PencilLine,
  Star,
  Trash,
  TrendingDown,
  TrendingUp,
  TrendingUpDown,
  Users,
} from "lucide-react";
import { getStats } from "../../api/dashboardApi";
import { useEffect, useState } from "react";
import MonthlyIncomeChart from "./IncomeGaph";
import LatestOrders from "./LatesOrders";

const DashboardPage = () => {
  const { theme } = useTheme();

  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const data = await getStats(); // Call the getStats function
        setStats(data); // Set the response data in the state
      } catch (err) {
        setError("Error fetching stats");
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchStats();
  }, []);


  return (
    <div className="flex flex-col gap-y-4">
      <h1 className="title">Dashboard</h1>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        <div className="card">
          <div className="card-header">
            <div className="w-fit rounded-lg bg-blue-500/20 p-2 text-blue-500 transition-colors  dark:text-blue-600">
              <Package size={26} />
            </div>
            <p className="card-title">Total Products</p>
          </div>
          <div className="card-body bg-slate-100 transition-colors ">
            <p className="text-3xl font-bold text-slate-900 transition-colors ">
              {" "}
              {stats?.productStats}
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <div className="rounded-lg bg-blue-500/20 p-2 text-blue-500 transition-colors dark:text-blue-600">
              <Users size={26} />
            </div>
            <p className="card-title">Total Customers</p>
          </div>
          <div className="card-body bg-slate-100 transition-colors ">
            <p className="text-3xl font-bold text-slate-900 transition-colors ">
              {" "}
              {stats?.userStats?.currentCount}
            </p>
            <span className="flex w-fit items-center gap-x-2 rounded-full border border-blue-500 px-2 py-1 font-medium text-blue-500  dark:text-blue-600">
              {stats?.userStats?.percentageChange >= 0 ? (
                <>
                  <TrendingUp size={18} /> {stats?.userStats?.percentageChange}
                </>
              ) : (
                <>
                  <TrendingDown size={18} />{" "}
                  {stats?.userStats?.percentageChange.toFixed(2)}
                </>
              )}{" "}
              %
            </span>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <div className="rounded-lg bg-blue-500/20 p-2 text-blue-500 transition-colors  ">
              <DollarSign size={26} />
            </div>
            <p className="card-title">Total Paid Orders</p>
          </div>
          <div className="card-body bg-slate-100 transition-colors ">
            <p className="text-3xl font-bold text-slate-900 transition-colors ">
              {" "}
              LKR {stats?.incomeStats?.currentIncome}
            </p>
            <span className="flex w-fit items-center gap-x-2 rounded-full border border-blue-500 px-2 py-1 font-medium text-blue-500  dark:text-blue-600">
              {stats?.incomeStats?.percentageChange >= 0 ? (
                <>
                  <TrendingUp size={18} />{" "}
                  {stats?.incomeStats?.percentageChange.toFixed(2)}
                </>
              ) : (
                <>
                  <TrendingUpDown size={18} />{" "}
                  {stats?.incomeStats?.percentageChange}
                </>
              )}{" "}
              %
            </span>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <div className="rounded-lg bg-blue-500/20 p-2 text-blue-500 transition-colors ">
              <CreditCard size={26} />
            </div>
            <p className="card-title">Sales</p>
          </div>
          <div className="card-body bg-slate-100 transition-colors ">
            <p className="text-3xl font-bold text-slate-900 transition-colors ">
              {stats?.orderStats?.currentCount}
            </p>
            <span className="flex w-fit items-center gap-x-2 rounded-full border border-blue-500 px-2 py-1 font-medium text-blue-500  ">
              {stats?.orderStats?.percentageChange >= 0 ? (
                <>
                  <TrendingUp size={18} />{" "}
                  {stats?.orderStats?.percentageChange.toFixed(2)}
                </>
              ) : (
                <>
                  <TrendingUpDown size={18} />{" "}
                  {stats?.incomeStats?.percentageChange}
                </>
              )}{" "}
              %
            </span>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-7">
        <div className="card col-span-1 md:col-span-2 lg:col-span-4">
          <div className="card-header">
            <p className="card-title">Overview</p>
          </div>
              <MonthlyIncomeChart/>
        </div>
        <div className="card col-span-1 md:col-span-2 lg:col-span-3">
            <LatestOrders/>
        </div>
      </div>
      <div className="card hidden">
        <div className="card-header">
          <p className="card-title">Top Orders</p>
        </div>
        <div className="card-body p-0">
          <div className="relative h-[500px] w-full flex-shrink-0 overflow-auto rounded-none [scrollbar-width:_thin]">
            <table className="table">
              <thead className="table-header">
                <tr className="table-row">
                  <th className="table-head">#</th>
                  <th className="table-head">Product</th>
                  <th className="table-head">Price</th>
                  <th className="table-head">Status</th>
                  <th className="table-head">Rating</th>
                  <th className="table-head">Actions</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {topProducts.map((product) => (
                  <tr key={product.number} className="table-row">
                    <td className="table-cell">{product.number}</td>
                    <td className="table-cell">
                      <div className="flex w-max gap-x-4">
                        <img
                          src={product.image}
                          alt={product.name}
                          className="size-14 rounded-lg object-cover"
                        />
                        <div className="flex flex-col">
                          <p>{product.name}</p>
                          <p className="font-normal text-slate-600 ">
                            {product.description}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="table-cell">${product.price}</td>
                    <td className="table-cell">{product.status}</td>
                    <td className="table-cell">
                      <div className="flex items-center gap-x-2">
                        <Star
                          size={18}
                          className="fill-yellow-600 stroke-yellow-600"
                        />
                        {product.rating}
                      </div>
                    </td>
                    <td className="table-cell">
                      <div className="flex items-center gap-x-4">
                        <button className="text-blue-500 ">
                          <PencilLine size={20} />
                        </button>
                        <button className="text-red-500">
                          <Trash size={20} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DashboardPage;
