import React from "react";

function Hero() {
  return (
    <div className="w-full pt-28 sm:pt-36 md:pt-48 flex flex-col  justify-center items-center ">
      <section className=" hidden sm:block max-w-screen-md">
        <h1 className="text-4xl  md:text-5xl text-_white">
          <span className=" text-6xl md:text-7xl font-gemunu">
            wdhqfndajka
          </span>{" "}
          <span className="font-semibold">to</span>{" "}
          <span className="font-bold">DOCTOR</span>Hub.lk
        </h1>
        <p className="text-_white  text-xs  md:text-base  text-right -translate-y-3">
          Sri Lanka's Best and Most Trusted Source for Quality Medical
          Accessories
        </p>
      </section>
      <section className="sm:hidden max-w-screen-md flex flex-col items-center justify-center">
        <div className="text-4xl text-_white flex flex-col items-center">
          <h1>
            <span className=" text-5xl font-gemunu">
              wdhqfndajka
            </span>{" "}
            <span className="font-semibold">to</span>
          </h1>{" "}
         <h2> <span className="font-bold">DOCTOR</span>Hub.lk</h2>
        </div>
        <p className="text-_white font text-xs px-8 pt-2 md:text-base text-center sm:text-right sm:-translate-y-3">
          Sri Lanka's Best and Most Trusted Source for Quality Medical
          Accessories
        </p>
      </section>
    </div>
  );
}

export default Hero;
