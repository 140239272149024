import React from 'react'

const HeaderTitle = ({title}) => {
  return (
       <div className="flex justify-between items-center relative">
              <h2 
                className="w-full text-3xl md:text-4xl lg:text-5xl font-bold text-center py-10 md:pt-24 pb-10 md:pb-16 text-_dark" 
                data-aos="fade-up"
              >
                {title}
                <div className="h-1 w-24 bg-_dark mx-auto mt-4 rounded"></div>
              </h2>
            </div>
  )
}

export default HeaderTitle