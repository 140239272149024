import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";


const StoreCategories = ({ categories, categoryId, setCategoryId, setPage }) => {
  // Function to create a URL-friendly slug
  const { categorySlug } = useParams();

  const generateSlug = (name) => {
    return name.toLowerCase().replace(/\s+/g, "-"); // Converts spaces to hyphens
  };

  const reverseSlug = (slug) => {
    return slug.replace(/-/g, " "); // Converts hyphens to spaces
  };
  
  // Function to get category ID by name
  const getCategoryIdByName = (name) => {
    const category = categories.find(
      (cat) => cat.name.toLowerCase() === name.toLowerCase()
    );
    return category ? category.id : null;
  };

    useEffect(() => {
      if (categorySlug) {
        const categoryName = reverseSlug(categorySlug);
        const id = getCategoryIdByName(categoryName);
        setCategoryId(id);
      } else {
        setCategoryId(null);
      }
    }, [categorySlug]);

  return (
    <div>
      <h1 className="text-start mb-4 text-sm font-bold">Categories</h1>
      <ul className="flex flex-col items-start lg:pl-4 gap-2 text-sm font-semibold">
        {categories.map((category) => {
          const categorySlug = category.id ? `/store/${generateSlug(category.name)}` : "/store/";

          return (
            <li key={category.id}>
              <Link
                to={categorySlug} // Dynamic link based on category name
                onClick={() => {
                  setCategoryId(category.id);
                  setPage(1);
                }}
                className={categoryId === category.id ? "text-blue-600" : ""}
              >
                {category.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default StoreCategories;
