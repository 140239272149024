import { useEffect, useState } from "react";
import axios from "axios";
import PaymentButton from "./OnlinePayment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { fetchUserProfile } from "../../api/authApi";
import CashOnDelivery from "./CashOnDelivery";
import CheckoutLoadingSkeleton from "./CheckoutLoadingSkeleton";

const Checkout = () => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);
  const [formData, setFormData] = useState({
    address: "",
    city: "",
    phone: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      toast.warn("Sorry, you need to login first!");
      sessionStorage.setItem("redirect", "/checkout");
      navigate("/auth/login");
      return;
    }

    setLoading(true);
    fetchUserProfile(token)
      .then((data) => {
        setUserDetails(data);
        setFormData({
          address: data.address || "",
          city: data.city || "",
          phone: data.phone || "",
        });
      })
      .catch(() => toast.error("Failed to load user profile"))
      .finally(() => setLoading(false));
  }, [navigate]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {loading ? (
        <CheckoutLoadingSkeleton />
      ) : (
        <div className="flex justify-center items-center mt-32 md:mt-48">
          {userDetails && (
            <div className="w-full max-w-screen-lg mx-4 lg:mx-0 grid grid-cols-1 md:grid-cols-3 border rounded-md">
              <div className="col-span-1 py-16 md:py-0 px-10 bg-_blue rounded-t-md lg:rounded-l-md">
                <div className="flex flex-col items-center justify-center h-full">
                  <h1 className="flex justify-center items-center text-6xl text-_white font-bold border size-24 rounded-full">
                    <p>1</p>
                  </h1>
                  <h4 className="text-_white text-center font-semibold text-2xl mt-4">
                    Shipping Details
                  </h4>
                </div>
              </div>
              <div className="col-span-2 p-6 bg-_white text-_dark shadow-md rounded-b-md lg:rounded-r-md space-y-4">
                <h2 className="text-xl font-semibold">
                  Enter Your Shipping Details
                </h2>
                <div>
                  <label className="block text-left text-_gray font-normal">
                    Address:
                  </label>
                  <input
                    type="text"
                    name="address"
                    className="appearance-none block w-full text-_dark border border-gray-300 rounded py-3 px-4 mb-3"
                    placeholder="Address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label className="block text-left text-_gray font-normal">
                    City:
                  </label>
                  <input
                    type="text"
                    name="city"
                    className="appearance-none block w-full text-_dark border border-gray-300 rounded py-3 px-4 mb-3"
                    placeholder="City"
                    value={formData.city}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label className="block text-left text-_gray font-normal">
                    Phone:
                  </label>
                  <input
                    type="text"
                    name="phone"
                    className="appearance-none block w-full text-_dark border border-gray-300 rounded py-3 px-4 mb-3"
                    placeholder="Phone number"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex justify-end pt-4 gap-4">
                  <PaymentButton
                    userDetails={userDetails}
                    shippingDetails={formData}
                  />
                  <CashOnDelivery shippingDetails={formData} />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Checkout;