import React from 'react'
import Contact from '../../components/Contact/Contact'

const ContactPage = () => {
  return (
    <div className='-mt-8'>

      <Contact/>
    </div>
  )
}

export default ContactPage
