import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CheckoutLoadingSkeleton = () => {
  return (
    <div className="flex justify-center items-center mt-32 md:mt-48">
      <div className="w-full max-w-screen-lg mx-4 lg:mx-0 grid grid-cols-1 md:grid-cols-3 border rounded-md">
        {/* Left column - blue section */}
        <div className="col-span-1 py-16 md:py-0 px-10 bg-_blue rounded-t-md lg:rounded-l-md">
     
        </div>

        {/* Right column - form section */}
        <div className="col-span-2 p-6 bg-_white text-_dark shadow-md rounded-b-md lg:rounded-r-md space-y-4">
          {/* Title */}
          <div>
            <Skeleton height={24} width={250} />
          </div>

          {/* Address field */}
          <div>
            <Skeleton height={18} width={80} className="mb-2" />
            <Skeleton height={48} width="100%" />
          </div>

          {/* City field */}
          <div>
            <Skeleton height={18} width={50} className="mb-2" />
            <Skeleton height={48} width="100%" />
          </div>

          {/* Phone field */}
          <div>
            <Skeleton height={18} width={70} className="mb-2" />
            <Skeleton height={48} width="100%" />
          </div>

          {/* Buttons */}
          <div className="flex justify-end pt-4 gap-4">
            <Skeleton height={42} width={120} borderRadius={4} />
            <Skeleton height={42} width={150} borderRadius={4} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutLoadingSkeleton;