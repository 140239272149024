import React, { useEffect, useRef, useState } from "react";
import { RiArrowRightDoubleFill, RiFilter2Line } from "react-icons/ri";
import StorePriceFilter from "./StorePriceFilter";
import StoreCategories from "../../components/StoreCategories";
import { categories } from "./Data";

const StoreFloatingFilter = ({
  categoryId,
  setCategoryId,
  setPage,
  minPrice,
  setMinPrice,
  maxPrice,
  setMaxPrice,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const sectionRef = useRef(null);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (sectionRef.current && !sectionRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen]);

  return (
    <section
      ref={sectionRef}
      className="md:hidden fixed top-24 sm:top-28  left-0 flex flex-row items-start justify-center"
    >
      <div
        className={`w-56 pl- h-full  absolute transition-transform duration-300 ${
          isOpen ? "translate-x-28" : "-translate-x-[72px]"
        }`}
      >
        <div className="flex flex-row items-start absolute">
          <div className="bg-_gray_lite bg-opacity-75 shadow-md backdrop-blur-md rounded-r-lg border px-4 py-4 sm:py-8">
            <section className="md:hidden  clear-start text-_dark">
              <StoreCategories
                categories={categories}
                categoryId={categoryId}
                setCategoryId={setCategoryId}
                setPage={setPage}
              />
              <hr className="my-4 sm:my-8" />
              <div>
                <StorePriceFilter
                  setPage={setPage}
                  minPrice={minPrice}
                  setMinPrice={setMinPrice}
                  maxPrice={maxPrice}
                  setMaxPrice={setMaxPrice}
                />
              </div>
            </section>
          </div>
          <button
            className="divx translate-y-14 flex flex-row items-center  justify-center opacity-60 border bg-_dark hover:scale-105 bg-opacity-50 py-2 rounded-r-md cursor-pointer z-10"
            onClick={toggleSidebar}
          >
            <RiFilter2Line className="size-6 text-_white opacity-80" />
            <RiArrowRightDoubleFill
              className={`text-_white opacity-80 transition-transform duration-300 ${
                isOpen ? "rotate-180" : "rotate-0"
              }`}
            />
          </button>
        </div>
      </div>
    </section>
  );
};

export default StoreFloatingFilter;
