import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const googleLogin = async (token) => {
  try {
    const response = await axios.post(`${baseURL}/api/users/google/`, { token });
    return response.data;
  } catch (error) {
    console.error("Google Login API Error:", error);
    return { error: error.response?.data?.message || "Network error" };
  }
};

export const registerUser = async (userData) => {
  try {
    const response = await axios.post(`${baseURL}/api/users/`, userData);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || "Registration failed. Please try again.";
  }
};

export const loginUser = async (loginData) => {
  try {
    const response = await axios.post(`${baseURL}/api/users/login`, loginData);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || "Login failed. Please check your credentials.";
  }
};

export const fetchUserProfile = async (token) => {
  try {
    const response = await axios.get(`${baseURL}/api/users/profile`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user data", error);
    throw error;
  }
};

export const updateUserProfile = async (token, formData) => {
  try {
    const response = await axios.put(`${baseURL}/api/users/update`, formData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error updating user profile", error);
    throw error;
  }
};


export const fetchUserOrders = async (token) => {
  try {
      const response = await axios.get(`${baseURL}/api/users/order`, {
          headers: {
              Authorization: `Bearer ${token}`,
          },
      });
      return response.data;
  } catch (error) {
      console.error("Error fetching orders:", error);
      throw error;
  }
};