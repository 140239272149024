import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function useScrollToWithOffset(offset = 100) {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      
      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset - offset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }, [hash, offset]);
}