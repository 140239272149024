import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TitleUpdater = () => {
  const location = useLocation();

  // Store titles in an object with route paths as keys
  const titles = {
    '/': 'DOCTORHub.lk',
    '/contact': 'DOCTORHub.lk | Contact Us',
    '/about': 'DOCTORHub.lk | About Us',
    '/store': 'DOCTORHub.lk | Store',
    '/store/tabs': 'DOCTORHub.lk | Store',
    '/store/tab-accessories': 'DOCTORHub.lk | Store',
    '/store/steth-accessories': 'DOCTORHub.lk | Store',
    '/cart': 'DOCTORHub.lk | Cart',
    '/checkout': 'DOCTORHub.lk | Checkout',
    '/profile': 'DOCTORHub.lk | Profile',
  };

  useEffect(() => {
    let title = titles[location.pathname] || 'DOCTORHub.lk';

    // For dynamic titles like product pages, check for dynamic params
    if (location.pathname.startsWith('/product/') && titles['/product/:id']) {
      const productId = location.pathname.split('/')[2]; // Extract product ID from the URL
      title = titles['/product/:id'](productId); // Set dynamic title
    }

    document.title = title;
  }, [location.pathname]); // Dependency on pathname to update title on route change

  return null; // This component doesn't need to render anything, it just updates the document title
};

export default TitleUpdater;
