import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import axios from 'axios';

const Orders = () => {
  // State variables
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [startDate, setStartDate] = useState('2025-01-01');
  const [endDate, setEndDate] = useState('2025-12-01');
  const [expandedOrders, setExpandedOrders] = useState({});

  // Fetch orders from API
  const fetchOrders = async () => {
    setLoading(true);
    try {
      // Get token from sessionStorage
      const token = sessionStorage.getItem('token');
      
      if (!token) {
        throw new Error('Authentication token not found');
      }

      // Make API call with query parameters
      const response = await axios.get(
        `http://localhost:5005/api/orders`, {
          params: {
            startDate,
            endDate,
            page,
            limit
          },
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      // Update state with response data
      const ordersData = response.data.orders || [];
      
      // Parse orderItems JSON string for each order
      const processedOrders = ordersData.map(order => {
        return {
          ...order,
          orderItems: typeof order.orderItems === 'string' 
            ? JSON.parse(order.orderItems) 
            : order.orderItems
        };
      });
      
      setOrders(processedOrders);
      
      // Calculate total pages if pagination info is available
      if (response.data.totalPages) {
        setTotalPages(response.data.totalPages);
      } else if (response.data.pagination) {
        setTotalPages(Math.ceil(response.data.pagination.total / limit));
      }
      
      setError(null);
    } catch (err) {
      setError(err.message || 'Failed to fetch orders');
      setOrders([]);
    } finally {
      setLoading(false);
    }
  };

  // Fetch orders when component mounts or when dependencies change
  useEffect(() => {
    fetchOrders();
  }, [page, limit, startDate, endDate]);

  // Handle page navigation
  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  // Handle date filter changes
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    setPage(1); // Reset to first page when filter changes
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    setPage(1); // Reset to first page when filter changes
  };

  // Toggle expanded state for order details
  const toggleOrderDetails = (orderId) => {
    setExpandedOrders(prev => ({
      ...prev,
      [orderId]: !prev[orderId]
    }));
  };

  // Formatting date for display
  const formatDate = (dateString) => {
    try {
      return format(new Date(dateString), 'MMM dd, yyyy HH:mm');
    } catch (error) {
      return dateString;
    }
  };

  // Determine payment method display text
  const getPaymentMethodText = (method) => {
    switch (method) {
      case '1':
        return 'ONLINE';
      case '2':
        return 'COD';
      default:
        return `Payment Method ${method}`;
    }
  };

  return (
    <div className="container mx-auto p-4 bg-white">
      <h1 className="text-2xl font-bold mb-4">Order Management</h1>
      
      {/* Date Filter Controls */}
      <div className="mb-4 flex flex-wrap gap-4">
        <div className="flex flex-col">
          <label htmlFor="startDate" className="mb-1">Start Date:</label>
          <input
            id="startDate"
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            className="border p-2 rounded"
          />
        </div>
        
        <div className="flex flex-col">
          <label htmlFor="endDate" className="mb-1">End Date:</label>
          <input
            id="endDate"
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            className="border p-2 rounded"
          />
        </div>
        
        <div className="flex flex-col">
          <label htmlFor="limit" className="mb-1">Items per page:</label>
          <select
            id="limit"
            value={limit}
            onChange={(e) => setLimit(Number(e.target.value))}
            className="border p-2 rounded"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
        </div>
      </div>

      {/* Error Message */}
      {error && (
        <div className="bg-red-100 text-red-700 p-4 mb-4 rounded">
          {error}
        </div>
      )}

      {/* Loading State */}
      {loading ? (
        <div className="text-center py-8">Loading orders...</div>
      ) : (
        <>
          {/* Orders Table */}
          {orders.length > 0 ? (
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-200">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="py-2 px-4 border text-left">Order ID</th>
                    <th className="py-2 px-4 border text-left">Date</th>
                    <th className="py-2 px-4 border text-left">Customer</th>
                    <th className="py-2 px-4 border text-left">City</th>
                    <th className="py-2 px-4 border text-left">Total</th>
                    <th className="py-2 px-4 border text-left">Payment</th>
                    <th className="py-2 px-4 border text-left">Status</th>
                    <th className="py-2 px-4 border text-left">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <React.Fragment key={order.orderId}>
                      <tr className="hover:bg-gray-50">
                        <td className="py-2 px-4 border">{order.orderId}</td>
                        <td className="py-2 px-4 border">{formatDate(order.createdAt)}</td>
                        <td className="py-2 px-4 border">
                          {order.firstName + " " + order.lastName} <br /> 
                          {order.shippingAddress} <br /> 
                          {order.phone}
                        </td>
                        <td className="py-2 px-4 border">{order.city}</td>
                        <td className="py-2 px-4 border">LKR {order.amount}</td>
                        <td className="py-2 px-4 border">
                          {getPaymentMethodText(order.paymentMethod)}
                          <br />
                          <span className={`
                            text-xs py-1 px-2 rounded 
                            ${order.paymentStatus === 'paid' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'}
                          `}>
                            {order.paymentStatus}
                          </span>
                        </td>
                        <td className="py-2 px-4 border">
                          <span 
                            className={`px-2 py-1 rounded text-xs font-medium
                              ${order.status === 'completed' ? 'bg-green-100 text-green-800' : ''}
                              ${order.status === 'pending' ? 'bg-yellow-100 text-yellow-800' : ''}
                              ${order.status === 'cancelled' ? 'bg-red-100 text-red-800' : ''}
                              ${!order.status ? 'bg-blue-100 text-blue-800' : ''}
                            `}
                          >
                            {order.orderStatus || 'processing'}
                          </span>
                        </td>
                        <td className="py-2 px-4 border">
                          <button 
                            className="bg-blue-500 text-white px-2 py-1 rounded text-sm mr-2"
                            onClick={() => toggleOrderDetails(order.orderId)}
                          >
                            {expandedOrders[order.orderId] ? 'Hide' : 'View'}
                          </button>
                        </td>
                      </tr>
                      
                      {/* Expanded Order Items Row */}
                      {expandedOrders[order.orderId] && (
                        <tr>
                          <td colSpan="9" className="py-4 px-6 border bg-gray-50">
                            <div className="mb-2 font-medium">Order Items:</div>
                            <table className="w-full border-collapse text-sm">
                              <thead className="bg-gray-200">
                                <tr>
                                  <th className="py-2 px-3 border text-left">Product</th>
                                  <th className="py-2 px-3 border text-left">Sub Product</th>
                                  <th className="py-2 px-3 border text-left">Quantity</th>
                                  <th className="py-2 px-3 border text-left">Unit Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {order.orderItems && order.orderItems.map((item) => (
                                  <tr key={item.orderItemId} className="hover:bg-white">
                                    <td className="py-2 px-3 border">{item.product}</td>
                                    <td className="py-2 px-3 border">{item.title}</td>
                                    <td className="py-2 px-3 border">{item.count}</td>
                                    <td className="py-2 px-3 border">LKR {item.unitPrice}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            
                            {/* Order Actions */}
                            <div className="mt-4 flex gap-2">
                              <button className="bg-blue-600 text-white px-3 py-1 rounded text-sm">
                                Update Status
                              </button>
                              <button className="bg-green-600 text-white px-3 py-1 rounded text-sm">
                                Print Invoice
                              </button>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="text-center py-8 bg-gray-50 rounded">
              No orders found for the selected date range.
            </div>
          )}

          {/* Pagination Controls */}
          {orders.length > 0 && (
            <div className="flex justify-between items-center mt-4">
              <div>
                Showing page {page} of {totalPages || 1}
              </div>
              <div className="flex gap-2">
                <button
                  onClick={handlePrevPage}
                  disabled={page <= 1}
                  className={`px-4 py-2 rounded ${
                    page <= 1 
                      ? 'bg-gray-200 text-gray-500 cursor-not-allowed' 
                      : 'bg-blue-500 text-white hover:bg-blue-600'
                  }`}
                >
                  Previous
                </button>
                <button
                  onClick={handleNextPage}
                  disabled={page >= totalPages}
                  className={`px-4 py-2 rounded ${
                    page >= totalPages 
                      ? 'bg-gray-200 text-gray-500 cursor-not-allowed' 
                      : 'bg-blue-500 text-white hover:bg-blue-600'
                  }`}
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Orders;