import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const LoadingSkeleton = ({ count = 12 }) => {
  return (
    <div className="flex p-8 lg:p-36 mt-12 flex-col md:flex-row gap-8 animate-pulse">
    {/* Product Image Skeleton */}
    <div className="w-full md:w-1/2">
      <div className="bg-gray-200 w-full aspect-square rounded-md"></div>
      <div className="flex mt-2 gap-2">
        <div className="bg-gray-200 w-16 h-16 rounded-md"></div>
        <div className="bg-gray-200 w-16 h-16 rounded-md"></div>
        <div className="bg-gray-200 w-16 h-16 rounded-md"></div>
      </div>
    </div>

    {/* Product Info Skeleton */}
    <div className="w-full md:w-1/2 flex flex-col gap-4">
      {/* Title */}
      <div className="h-8 bg-gray-200 rounded-md w-3/4"></div>
      
      {/* Rating */}
      <div className="flex items-center gap-2">
        <div className="flex">
          {[1, 2, 3, 4, 5].map((star) => (
            <div key={star} className="w-5 h-5 bg-gray-200 rounded-sm"></div>
          ))}
        </div>
        <div className="h-4 bg-gray-200 rounded-md w-16"></div>
      </div>
      
      {/* Price */}
      <div className="mt-2">
        <div className="h-10 bg-gray-200 rounded-md w-1/2"></div>
        <div className="h-4 bg-gray-200 rounded-md w-1/3 mt-2"></div>
        <div className="h-4 bg-gray-200 rounded-md w-1/4 mt-2"></div>
      </div>
      
      {/* Version selector */}
      <div className="mt-4">
        <div className="h-4 bg-gray-200 rounded-md w-1/3 mb-2"></div>
        <div className="h-10 bg-gray-200 rounded-md w-full"></div>
      </div>
      
      {/* Quantity */}
      <div className="mt-4">
        <div className="h-4 bg-gray-200 rounded-md w-1/4 mb-2"></div>
        <div className="h-10 bg-gray-200 rounded-md w-full"></div>
      </div>
      
      {/* Buttons */}
      <div className="flex gap-2 mt-6">
        <div className="h-12 bg-gray-200 rounded-md w-1/2"></div>
        <div className="h-12 bg-gray-200 rounded-md w-1/2"></div>
      </div>
    </div>
  </div>
  );
};

export default LoadingSkeleton;
 