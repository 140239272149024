/*global payhere*/
import React, { useState } from "react";
import { startPayment } from "../../api/productApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCart } from "../../Contexts/CartContext/CartContext";
const baseURL = process.env.REACT_APP_API_BASE_URL;

const PaymentButton = ({ userDetails, shippingDetails }) => {
  const [isLoading, setIsloading] = useState(false);
  const navigate = useNavigate();
  const { clearCart } = useCart();
  const handlePayment = async () => {
    setIsloading(true);
    if (!userDetails || !shippingDetails) {
      console.error("User or shipping details are missing");
      return;
    }

    const paymentDetails = {
      first_name: userDetails.first_name || "N/A",
      last_name: userDetails.last_name || "N/A",
      email: userDetails.email || "N/A",
      phone: shippingDetails.phone || "N/A",
      address: shippingDetails.address || "N/A",
      city: shippingDetails.city || "N/A",
      country: "Sri Lanka",
    };

    try {
      // Get cart items from localStorage
      const storedCart = JSON.parse(localStorage.getItem("cart")) || [];

      if (storedCart.length === 0) {
        console.error("Cart is empty");
        toast.error("You cart is emtey!");
        return;
      }

      // Call API
      const { hash, merchant_id, amount, order_id, currency } =
        await startPayment(paymentDetails, storedCart);

      console.log(merchant_id, order_id, amount, currency, hash);

      payhere.onCompleted = function onCompleted() {
        clearCart();
        toast.success("Payment completed successfully!");
        navigate("/profile");
      };
      payhere.onDismissed = function onDismissed () {
        toast.warning("pls complete the payment!");
      };
      payhere.onError = function onError () {
        toast.error("Please try again later.");
      };
      // Configure PayHere payment object
      const payment = {
        sandbox: true, // TODO:: make this false when deploying
        merchant_id,
        return_url: "http://localhost:3000/payment/success",
        cancel_url: "http://localhost:3000/payment/cancel",
        // notify_url: `${baseURL}/api/payments/notify`,  //TODO :: change this to production -- Ramishka
        notify_url: `https://api-doctorhub.vercel.app/api/orders/online/payment/notify`,
        order_id,
        items: "Online Checkout",
        amount,
        currency,
        first_name: paymentDetails.first_name,
        last_name: paymentDetails.last_name,
        email: paymentDetails.email,
        phone: paymentDetails.phone,
        address: paymentDetails.address,
        city: paymentDetails.city,
        country: paymentDetails.country,
        hash,
      };

      // Start PayHere Payment
      payhere.startPayment(payment);
    } catch (error) {
      console.error("Payment failed:", error);
    } finally {
      setIsloading(false);
    }
  };

  return (
    <div>
      <button
        disabled={isLoading}
        id="payhere-payment"
        onClick={handlePayment}
        className="border  bg-myRed py-2 px-4 text-_dark font-semibold rounded-lg bg-_gray_lite hover:bg-_gray_hover"
      >
        {!isLoading ? "Pay Online" : "Processing"}
      </button>
    </div>
  );
};

export default PaymentButton;
