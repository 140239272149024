import React, { useEffect, useState } from 'react';
import StarRating from "../StarRating/StarRating";

function ItemCard({ product, showButton = true }) {
  let discountPrice = product.discountPrice;
  let price = product.price;
  const [isProductPage, setIsProductPage] = useState(false);

  const mainPrice = discountPrice ? discountPrice : price;
  const strikePrice = discountPrice ? price : null;

  let discountRate = Math.floor(((price - discountPrice) / price) * 100);

  useEffect(() => {
    // Check if the current URL path contains "/product/"
    if (window.location.pathname.startsWith('/product/')) {
      setIsProductPage(true);
    } else {
      setIsProductPage(false);
    }
  }, []);

  return (
    <div className=" h-full w-full flex flex-col justify-between ">
      <div className=" flex flex-col justify-start rounded-lg shadow-lgx ">
        <div className="aspect-square w-full overflow-hidden bg-_white rounded-lg  ">
          <img
            className="w-full h-full object-fit bg-_g"
            src={product.imageURL}
            alt={product.title}
          />
        </div>
        <div className="pt-2 flex- flex flex-col justify-between ">
          <div className="flex flex-col items-start">
            <div className="">
              <div className="h-4">
              {product.discountPrice != null && (
                <h1 className="text-xs text-start text-_gray">
                  Save up to {discountRate}%
                </h1>
              )}
              </div>
              <h2 className="font-semibold text-start text-base  line-clamp-2 text-_dark">
                {product.title}
              </h2>
              <div className={`w-24 mt-1 ${isProductPage ? ' w-20' : ' w-24'}`}>
                <StarRating rating={product.averageRating || 5} />
              </div>
            </div>
            <div className="flex flex-col items-start mt-auto">
              <span className={`   ${isProductPage ? ' text-sm' : '  text-xl'} font-bold text-_darks text-_red mr-2 -mt- `}>
                LKR {mainPrice}
              </span>
              <span className="text-sm font-semibold text-_gray -my-1 line-through">
                {strikePrice}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={`w-full pt-4 pb-2 ${showButton ? 'block' : 'hidden'}`}>
      <button className="w-full bg-_reds bg-_dark hover:opacity-95 text-white font-bold py-2 px-4 rounded">
        Buy Now
      </button>
    </div>
    </div>
  );
}

export default ItemCard;
