export const products = [
    {
      id: 1,
      title: "21 Choices! Medical Nurse Storage Travel Carry Case fits 3M Littmann Stethoscope",
      rating : "4.2",
      originalPrice: "LKR 10999.00",
      discountedPrice: "LKR 9999.00",
      imageUrl: "https://i.ebayimg.com/images/g/EkgAAOSwkAReHIn6/s-l500.webp",
    },
    {
      id: 2,
      title: "21 Choices! Medical Nurse Storage Travel Carry Case fits 3M Littmann Stethoscope",
      rating : "4.2",
      originalPrice: "LKR 10999.00",
      discountedPrice: "LKR 9999.00",
      imageUrl: "https://i.ebayimg.com/images/g/EkgAAOSwkAReHIn6/s-l500.webp",
    },
    {
      id: 3,
      title: "21 Choices! Medical Nurse Storage Travel Carry Case fits 3M Littmann Stethoscope",
      rating : "4.2",
      originalPrice: "LKR 10999.00",
      discountedPrice: "LKR 9999.00",
      imageUrl: "https://i.ebayimg.com/images/g/EkgAAOSwkAReHIn6/s-l500.webp",
    },
    {
      id: 4,
      title: "21 Choices! Medical Nurse Storage Travel Carry Case fits 3M Littmann Stethoscope",
      rating : "4.2",
      originalPrice: "LKR 10999.00",
      discountedPrice: "LKR 9999.00",
      imageUrl: "https://i.ebayimg.com/images/g/EkgAAOSwkAReHIn6/s-l500.webp",
    },
    {
      id: 1,
      title: "21 Choices! Medical Nurse Storage Travel Carry Case fits 3M Littmann Stethoscope",
      rating : "4.2",
      originalPrice: "LKR 10999.00",
      discountedPrice: "LKR 9999.00",
      imageUrl: "https://i.ebayimg.com/images/g/EkgAAOSwkAReHIn6/s-l500.webp",
    },
    // Add more products as needed
  ];

  export const categories = [
    { id: null, name: "All" },
    { id: 1, name: "Tabs" },
    { id: 2, name: "Tab Accessories" },
    { id: 3, name: "Steth Accessories" },
  ];