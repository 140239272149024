import { RiFacebookBoxFill, RiWhatsappFill } from "react-icons/ri";
import logo from '../../assets/logo.png';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  // In your Navbar component
const handleClick = () => {
  // Check if we're already on the home page
  if (window.location.pathname === '/') {
    // Scroll to section directly
    const element = document.getElementById('batchwise');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  } else {
    // Navigate to home page with hash
    navigate('/#batchwise');
  }
};
  return (
    <footer className="bg-_white border-t border-_gray_lite py-10 mt-32">
      <div className="max-w-screen-lg mx-auto px-6">
        {/* Top Section */}
        <div className="flex flex-col items-center text-center sm:text-left sm:flex-row sm:justify-between">
          <div>
            <img src={logo} alt="" />
            <p className="mt-2 text-_gray pr-0 md:pr-16 text-start">
            Supporting medical students and doctors with resources and opportunities.
            </p>
          </div>
          <div className="text-2xl flex flex-row justify-start gap-4 mt-4 sm:mt-0 text-_dark">
            <Link to={""}>
            <RiFacebookBoxFill/>
            </Link>
            <Link to={""}>
            <RiWhatsappFill/>
            </Link>
          </div>
        </div>

        {/* Footer Links */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mt-8 text-_gray ">
          <div>
            <h3 className="font-semibold text-_dark text-start">Our Store</h3>
            <ul className="mt-2 space-y-2">
              <li className="text-start"><Link to={"store"}>All products</Link></li>
              <li className="text-start"><Link to={"store/tab-accessories"}>Tab Accessories</Link>
              </li>
              <li className="text-start"><Link to={"store/tabs"}>Tabs</Link></li>
              <li className="text-start"><Link to={"store/steth-accessories"}>Steth Accessories</Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="font-semibold text-_dark text-start"><Link>Support</Link></h3>
            <ul className="mt-2 space-y-2">
              <li className="text-start"><Link to={"contact"}>Contact</Link></li>
              <li className="text-start"><Link onClick={handleClick}>Batchweise purchases</Link></li>

            </ul>
          </div>
          <div>
            <h3 className="font-semibold text-_dark text-start">Quick Access</h3>
            <ul className="mt-2 space-y-2">
              <li className="text-start"><Link to={"about"}>About us</Link></li>
              <li className="text-start"><Link to={"store"}>Store</Link></li>
              <li className="text-start"><Link to={"cart"}>Cart</Link></li>
              <li className="text-start"><Link to={"profile"}>Profile</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="font-semibold text-_dark text-start">Legal</h3>
            <ul className="mt-2 space-y-2">
              <li className="text-start"><Link>Terms of service</Link></li>
              <li className="text-start"><Link>Privacy policy</Link></li>
              <li className="text-start"><Link>License</Link></li>
            </ul>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="mt-10 text-center text-_gray border-t pt-6">
          © 2024 <span className="hover:underline"><Link to={"https://web.facebook.com/profile.php?id=61563620580575"}>devX</Link></span>, Inc. All rights reserved.
        </div>
      </div>
    </footer>
  );
}
