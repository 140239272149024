import React from "react";
import { RiArrowRightUpLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import samsung from '../../assets/product-catagories/samsung.png';
import xiaomi from '../../assets/product-catagories/xiaomi.png';
import littman from '../../assets/product-catagories/littman.png';
import mdm from '../../assets/product-catagories/mdm.png';
import dualRhythm from '../../assets/product-catagories/dual-rhythm.png';
import SubCategoryCard from "./SubCatagoryCard";
import HeaderTitle from "../headerTitle/HeaderTitle";

const ProductCategories = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full bg-white pt-4">
      <div className="w-full max-w-screen-lg px-4 lg:px-0">
      <HeaderTitle title={"Our Categories"}/>

        
        <div className="bg-_white rounded-lg shadow-sm grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 p-6 lg:p-8 gap-8 my-8 mx-2 lg:mx-0">
          {/* TABLETS SECTION */}
          <div 
            className="flex flex-col gap-4 items-start md:col-span-2 lg:col-span-1"
            data-aos="fade-up"
            data-aos-duration="500"
          >
            <h2 className="text-3xl md:text-4xl font-bold text-_blue mb-2">Tablets</h2>
            <p className="text-_gray text-base leading-relaxed">
              Explore our range of tablets, designed for performance and style.
            </p>
            <Link to="/store/tabs" className="mt-4">
              <button className="flex items-center justify-center bg-_blue hover:bg-opacity-90 transition-all duration-300 px-5 py-2.5 gap-2 rounded-md text-_white">
                <span className="font-medium">Shop now</span>
                <RiArrowRightUpLine className="text-lg"/>
              </button>
            </Link>
          </div>
          
          <SubCategoryCard
            placeholderImg={samsung} 
            title="Samsung" 
            paragraph="Premium tablets with stunning displays and powerful performance." 
            path="/store/tabs"
          />
          
          <SubCategoryCard 
            placeholderImg={xiaomi} 
            title="Xiaomi" 
            paragraph="Feature-rich tablets offering exceptional value and innovation." 
            path="/store/tabs"
          />
          
          <hr className="w-full h-0.5 bg-_gray_lite col-span-1 md:col-span-2 lg:col-span-3 my-12" />
          
          {/* TABLET ACCESSORIES SECTION */}
          <div 
            className="flex flex-col gap-4 items-start col-span-1 md:col-span-2 lg:col-span-1"
            data-aos="fade-up"
            data-aos-duration="500"
          >
            <h2 className="text-3xl md:text-4xl font-bold text-_blue mb-2">Tablet Accessories</h2>
            <p className="text-_gray text-base leading-relaxed">
              Enhance your tablet experience with our premium accessories designed for protection and productivity.
            </p>
            <Link to="/store/tab-accessories" className="mt-4">
              <button className="flex items-center justify-center bg-_blue hover:bg-opacity-90 transition-all duration-300 px-5 py-2.5 gap-2 rounded-md text-_white">
                <span className="font-medium">Shop now</span>
                <RiArrowRightUpLine className="text-lg"/>
              </button>
            </Link>
          </div>
          
          <SubCategoryCard
            placeholderImg={samsung} 
            title="Tempered Glass" 
            paragraph="Premium screen protectors for crystal clear viewing and optimal protection." 
            path="/store/tab-accessories"
          />
          
          <SubCategoryCard 
            placeholderImg={xiaomi} 
            title="Stylus Pens" 
            paragraph="Precision writing and drawing tools for enhanced tablet productivity." 
            path="/store/tab-accessories"
          />
          
          <hr className="w-full h-0.5 bg-_gray_lite col-span-1 md:col-span-2 lg:col-span-3 my-12" />

          {/* STETHOSCOPE ACCESSORIES SECTION */}
          <div 
            className="col-span-1 md:col-span-2 lg:col-span-3 flex flex-col items-start justify-center pt-8 pb-4 gap-4"
            data-aos="fade-up"
            data-aos-duration="500"
          >
            <div className="flex flex-col gap-4 items-start">
              <h2 className="text-3xl md:text-4xl font-bold text-_blue mb-2">Stethoscope Accessories</h2>
              <p className="text-_gray text-base leading-relaxed">
                Optimize your medical practice with high-quality stethoscope accessories designed for comfort and precision.
              </p>
              <Link to="/store/steth-accessories" className="mt-4 mb-8">
                <button className="flex items-center justify-center bg-_blue hover:bg-opacity-90 transition-all duration-300 px-5 py-2.5 gap-2 rounded-md text-_white">
                  <span className="font-medium">Shop now</span>
                  <RiArrowRightUpLine className="text-lg"/>
                </button>
              </Link>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full">
              <SubCategoryCard 
                placeholderImg={littman} 
                title="Littmann" 
                paragraph="Professional-grade accessories for the world's leading stethoscope brand." 
                path="/store/steth-accessories"
              />
              
              <SubCategoryCard 
                placeholderImg={mdm} 
                title="MDF" 
                paragraph="Premium quality stethoscope accessories with lifetime warranty." 
                path="/store/steth-accessories"
              />
              
              <SubCategoryCard 
                placeholderImg={dualRhythm} 
                title="Dual Rhythm" 
                paragraph="Innovative accessories designed for enhanced acoustic performance." 
                path="/store/steth-accessories"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCategories;