// StarRating.jsx
import React from 'react';
import { RiStarLine, RiStarHalfFill, RiStarFill } from "react-icons/ri";

const StarRating = ({ rating }) => {
  const totalStars = 5;
  const fullStars = Math.floor(rating); // Number of full stars
  const partialStar = rating % 1; // Decimal part for half-star
  const emptyStars = totalStars - fullStars - (partialStar > 0 ? 1 : 0); // Remaining empty stars

  return (
    <div className="flex items-center">
      {/* Full Stars */}
      {[...Array(fullStars)].map((_, i) => (
        <RiStarFill key={`full-${i}`} className="text-yellow-400 text-xl" />
      ))}

      {/* Half Star (if applicable) */}
      {partialStar > 0 && (
        <RiStarHalfFill key="half" className="text-yellow-400 text-xl" />
      )}

      {/* Empty Stars */}
      {[...Array(emptyStars)].map((_, i) => (
        <RiStarLine key={`empty-${i}`} className="text-gray-300 text-xl" />
      ))}
    </div>
  );
};

export default StarRating;