import React from "react";
import Hero from "../../components/Hero/Hero";
import Brief from "../../components/Brief/Brief";
import Batchweise from "../../components/Batchwise/Batchweise";
import Contact from "../../components/Contact/Contact";
import SocialBar from "../../components/SocialBar/SocialBar";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useScrollToWithOffset from "../../Hooks/Scrolling-hook/useScrollToWithOffset";
import ProductCatagories from "../../components/ProductCatagories/ProductCatagories";
import HotProducts from "../../components/hotProduct/HotProduct";

function Landing() {
  useScrollToWithOffset(-400);

  return (
    <div className="bg-gradient-to-tl from-_bg_lite to-_bg_dark ">
      <br />
      <Hero />
      <Brief />

      {/* <div id='batchwise'>

      <Batchweise/>
      </div> */}
      <div className="bg-_white">
        <HotProducts />
      </div>
      <div>
        <ProductCatagories />
      </div>
      <div className="bg-_white">
        {/* <HotProducts /> */}
        <Contact />
      </div>
      <SocialBar />
    </div>
  );
}

export default Landing;
