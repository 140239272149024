import React from 'react';

const ProfileSkeleton = () => {
  return (
    <div className="p-4 sm:p-6 max-w-4xl mx-auto bg-white rounded-lg shadow-sm w-full">
      <div className="mb-6">
        <div className="h-7 sm:h-8 w-24 sm:w-32 bg-gray-200 rounded animate-pulse"></div>
      </div>
      
      <div className="flex flex-col sm:flex-row sm:items-center mb-6 sm:mb-8 gap-4 sm:gap-0">
        <div className="w-14 h-14 sm:w-16 sm:h-16 bg-gray-200 rounded-full animate-pulse"></div>
        <div className="sm:ml-4">
          <div className="h-5 sm:h-6 w-36 sm:w-48 bg-gray-200 rounded animate-pulse mb-2"></div>
          <div className="h-4 w-44 sm:w-56 bg-gray-200 rounded animate-pulse"></div>
        </div>
      </div>
      
      <div className="space-y-4 sm:space-y-6">
        <div className="h-10 sm:h-12 w-full bg-gray-200 rounded animate-pulse"></div>
        <div className="h-10 sm:h-12 w-full bg-gray-200 rounded animate-pulse"></div>
        <div className="h-10 sm:h-12 w-full bg-gray-200 rounded animate-pulse"></div>
        
        <div className="mt-6 sm:mt-8">
          <div className="h-10 sm:h-12 w-full bg-gray-200 rounded animate-pulse"></div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSkeleton;