import React, { useState } from "react";
import PropTypes from "prop-types";
import StarRating from "../../components/StarRating/StarRating";
import { getRelativeTime } from "./dateUtils";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";

const ProductReview = ({ reviews = [] }) => {
  const [showAll, setShowAll] = useState(false);
  const hasMoreReviews = reviews.length > 6;
  
  // Display only first 6 reviews when not showing all
  const displayedReviews = showAll ? reviews : reviews.slice(0, 6);

  return (
    <div className="">
      <h1 className="text-_dark text-2xl text-start mb-8">
        Reviews ({reviews.length})
      </h1>

      {displayedReviews.map((review) => (
        <div
          key={review.reviewId}
          className="flex flex-col py-8 border-b border-_gray text-_dark"
        >
          <div className="w-full flex flex-col sm:flex-row justify-between mb-4 gap-4">
            <div className="flex flex-row items-center gap-4">
              <img
                src={review.profileImg || "https://via.placeholder.com/64"}
                alt={`User ${review.userId}`}
                className="size-14 rounded-full border border-_gray object-cover"
                referrerPolicy="no-referrer"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "https://via.placeholder.com/64"; // add backup img
                }}
              />
              <div>
                <h2 className="font-semibold text-lg text-start">
                  {review.firstName + " " + review.lastName  || `User ${review.userId}`}
                </h2>
                <p className="text-_gray text-sm text-start">
                  {getRelativeTime(review.createdAt)}
                </p>
              </div>
            </div>
            <div className="flex flex-row items-center gap-2 sm:ml-auto text-_dark">
              <p className="font-medium">{review.stars.toFixed(1)}</p>
              <StarRating rating={review.stars} />
            </div>
          </div>
          <div>
            <p className="text-start text-_dark">{review.comment}</p>
          </div>
        </div>
      ))}

      {hasMoreReviews && (
        <div className="w-full flex justify-center">
          <button
            onClick={() => setShowAll(!showAll)}
            className="mt-20 text-_gray text-opacity-80 hover:text-opacity-70 focus:outline-none transition-colors duration-200"
            aria-expanded={showAll}
            aria-label={showAll ? "Show fewer reviews" : "Show all reviews"}
          >
            {showAll ? (
              <div className="flex flex-col items-center justify-center">
                <RiArrowUpSLine />
                <p className="">Show less</p>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center">
                <p>Show more ({reviews.length - 6})</p>
                <RiArrowDownSLine />
              </div>
            )}
          </button>
        </div>
      )}

      {reviews.length === 0 && (
        <p className="text-gray-500 py-6">
          No reviews yet. Be the first to review!
        </p>
      )}
    </div>
  );
};

ProductReview.propTypes = {
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      reviewId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      userName: PropTypes.string,
      createdAt: PropTypes.string.isRequired,
      comment: PropTypes.string.isRequired,
      stars: PropTypes.number.isRequired,
      userImage: PropTypes.string,
    })
  ),
};

export default ProductReview;