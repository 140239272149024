import { RiArrowRightSLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

const Breadcrumb = ({globalProductName = "Loading"}) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const [categoryName, setCategoryName] = useState("Category");
  const [productName, setProductName] = useState("Product");

  // Simulate fetching product details (Replace with actual API call)
  useEffect(() => {
    if (pathnames[0] === "product" && pathnames.length === 2) {
          setProductName(globalProductName);
    }
  }, []); // ✅ Avoids infinite loop

  return (
    <nav className="">
      <ul className="flex items-start justify-start text-_gray font-semibold">
        <li>
          <Link to="/" className="hover:text-_dark">Home</Link>
        </li>

        {pathnames.map((value, index) => {
          let displayName = value.replace(/-/g, " ");
          let to = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === pathnames.length - 1;

          // Special handling for product pages
          if (pathnames[0] === "product" && pathnames.length === 2) {
            if (index === 0) {
              displayName = "Store";
              to = "/store";
            } else if (index === 1) {
              displayName = productName;
            }
          }

          return (
            <li key={to} className="flex justify-center items-center">
              <span className="mx-2"><RiArrowRightSLine className="text-sm md:text-lg translate-y-0.5" /></span>
              {isLast ? (
                <span className="text-_gray opacity-60">{displayName}</span>
              ) : (
                <Link to={to} className="hover:text-_dark capitalize">
                  {displayName}
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Breadcrumb;
