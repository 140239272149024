import React, { useEffect, useState, useRef } from "react";
import { fetchProducts } from "../../api/productApi.js";
import ItemCard from "../ItemCard/ItemCard.jsx";
import { Link } from "react-router-dom";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import HeaderTitle from "../headerTitle/HeaderTitle.jsx";

const HotProducts = () => {
  const [productsData, setProductsData] = useState({
    products: [],
    page: 1,
    totalPages: 1,
    totalProducts: 0
  });
  const [categoryId, setCategoryId] = useState(null);
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [sort, setSort] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isScrolling, setIsScrolling] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [visibleProducts, setVisibleProducts] = useState(4);
  
  const sliderRef = useRef(null);
  const limit = 10; // Set limit to 10 products
  const page = 1; // Always load first page

  // Handle responsive sizing
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setVisibleProducts(6);
      } else if (window.innerWidth >= 768) {
        setVisibleProducts(4);
      } else if (window.innerWidth >= 640) {
        setVisibleProducts(2);
      } else {
        setVisibleProducts(1);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await fetchProducts(
          categoryId,
          page,
          limit,
          minPrice,
          maxPrice,
          sort
        );
        setProductsData(response || {
          products: [],
          page: 1,
          totalPages: 1,
          totalProducts: 0
        });
      } catch (error) {
        console.error("Error fetching products:", error);
        // More user-friendly error approach
      } finally {
        // Add slight delay to avoid flickering on fast connections
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      }
    };
    fetchData();
  }, [categoryId, minPrice, maxPrice, sort]);

  const products = productsData?.products || [];

  // Mouse slide event handlers (not drag)
  const handleMouseDown = (e) => {
    if (!sliderRef.current) return;
    setIsScrolling(true);
    setStartX(e.pageX);
    setScrollLeft(sliderRef.current.scrollLeft);
    // Change cursor to indicate sliding
    sliderRef.current.style.cursor = 'grabbing';
  };

  const handleMouseLeave = () => {
    setIsScrolling(false);
    if (sliderRef.current) {
      sliderRef.current.style.cursor = 'grab';
    }
  };

  const handleMouseUp = () => {
    setIsScrolling(false);
    if (sliderRef.current) {
      sliderRef.current.style.cursor = 'grab';
    }
  };

  const handleMouseMove = (e) => {
    if (!isScrolling || !sliderRef.current) return;
    e.preventDefault();
    const x = e.pageX;
    const walk = (startX - x) * 2; // Scroll speed multiplier
    sliderRef.current.scrollLeft = scrollLeft + walk;
  };

  // Handle scroll with buttons
  const handleScrollLeft = (scrollAmount = 0) => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft -= scrollAmount || sliderRef.current.offsetWidth / 2;
    }
  };

  const handleScrollRight = (scrollAmount = 0) => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft += scrollAmount || sliderRef.current.offsetWidth / 2;
    }
  };

  // Loading skeleton component
  const ProductSkeleton = () => {
    return (
      <div className="flex-none transition-all duration-300 max-w-[220px] sm:max-w-[300px] md:max-w-[400px] animate-pulse">
        <div className="bg-gray-200 rounded-lg h-48 sm:h-56 md:h-64 mb-4"></div>
        <div className="bg-gray-200 h-6 rounded w-3/4 mb-2"></div>
        <div className="bg-gray-200 h-4 rounded w-1/2 mb-4"></div>
        <div className="bg-gray-200 h-8 rounded w-1/3"></div>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center justify-center pt-2 bg-white">
      <div className="w-full max-w-screen-xl px-4 sm:px-6 lg:px-8">
        <HeaderTitle title={"Hot Products"}/>
        
        <div className="flex flex-row justify-end gap-2 mb-6" data-aos="fade-up">
          <button 
            className="p-3 rounded-full shadow hover:shadow-md transition-all duration-300 bg-white text-gray-700 border border-gray-200 hover:bg-gray-50"
            onClick={() => handleScrollLeft()}
            aria-label="Scroll left"
          >
            <RiArrowLeftSLine className="text-xl" />
          </button>
          <button 
            className="p-3 rounded-full shadow hover:shadow-md transition-all duration-300 bg-white text-gray-700 border border-gray-200 hover:bg-gray-50"
            onClick={() => handleScrollRight()}
            aria-label="Scroll right"
          >
            <RiArrowRightSLine className="text-xl" />
          </button>
        </div>

        <div className="relative" data-aos="fade-up">
          <div 
            className="overflow-x-auto hide-scrollbar"
            ref={sliderRef}
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseLeave}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            style={{ cursor: 'grab' }}
          >
            <div 
              className="flex gap-6 transition-transform duration-300 pb-8"
              style={{ width: "max-content" }}
            >
              {isLoading ? (
                // Skeleton loading state
                Array.from({ length: visibleProducts }).map((_, index) => (
                  <div 
                    key={`skeleton-${index}`} 
                    className="flex-none"
                    style={{ width: `calc((100vw - 128px) / ${visibleProducts})` }}
                  >
                    <ProductSkeleton />
                  </div>
                ))
              ) : (
                // Actual products
                products.length > 0 ? (
                  products.map((product) => (
                    <Link to={`/product/${product.mainProductId}`}>
                    <div 
                      key={product.mainProductId} 
                      className="flex-none transition-all duration-300 max-w-[220px] sm:max-w-[300px] md:max-w-[400px] "
                      style={{ width: `calc((100vw - 128px) / ${visibleProducts})` }}
                    >
                      <Link 
                        to={`/product/${product.mainProductId}`}
                        className="block"
                        style={{ pointerEvents: isScrolling ? 'none' : 'auto' }}
                      >
                        <ItemCard product={product} showButton={false} />
                      </Link>
                    </div>
                    </Link>
                  ))
                ) : (
                  <div className="flex justify-center items-center h-64 w-full border border-gray-200 rounded-lg bg-gray-50">
                    <div className="text-center p-6">
                      <p className="text-lg text-gray-500 mb-2">No products found</p>
                      <p className="text-sm text-gray-400">Try adjusting your search criteria</p>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        
        {products.length > 0 && !isLoading && (
          <div className="flex justify-center mt-4 mb-12">
            <Link to="/store" className="px-6 py-3 bg-_blue text-white rounded-md hover:bg-blue-700 transition-colors duration-300 flex items-center gap-2 shadow-md">
              See More Products
              <RiArrowRightSLine className="text-xl" />
            </Link>
          </div>
        )}
      </div>

      <style jsx>{`
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
        .hide-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
      `}</style>
    </div>
  );
};

export default HotProducts;