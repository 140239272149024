import React, { useState } from "react";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";

const ProductDescription = ({ description }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="relative text-_dark">
      <h1 className="text-2xl text-start">Description</h1>
      <div
        className={`relative overflow-hidden transition-all duration-200 ease-in-out ${
          isExpanded ? "max-h-[5000px]" : "max-h-[200px]"
        }`}
      >
        <p className="text-lg font-normal py-12">
          { description?.split("\n").map((line, index) => (
            <p key={index} className="mb-1">
              {line}
            </p>
          )) || "No description available"}
        
        </p>
        {!isExpanded && (
          <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-white to-transparent pointer-events-none"></div>
        )}
      </div>
      <div className="w-full flex justify-center">
        <button
          onClick={toggleExpand}
          className="mt-2 text-_gray text-opacity-80 hover:text-opacity-70 focus:outline-none transition-colors duration-200 "
        >
          {isExpanded ? (
            <div className="flex flex-col items-center justify-center ">
              <RiArrowUpSLine />
              <p className="">Show less</p>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center">
              <p>Show more</p>
              <RiArrowDownSLine />
            </div>
          )}
        </button>
      </div>
    </div>
  );
};

export default ProductDescription;
