import React from "react";
import { RiPhoneFill, RiMapPinLine, RiMailLine } from "react-icons/ri";
import HeaderTitle from "../headerTitle/HeaderTitle";

const Contact = () => {
  const contacts = [
    {
      title: "Rajarata University",
      name: "Kavishka Kumarasinghe",
      phone: "076 965 7297",
      role: "Sales Representative"
    },
    {
      title: "Rajarata University",
      name: "Tharindu Kumarasinghe",
      phone: "076 165 9655",
      role: "Customer Support"
    },
    {
      title: "Rajarata University",
      name: "Adeesha Perera",
      phone: "072 710 6225",
      role: "Product Specialist"
    },
    {
      title: "Moratuwa University",
      name: "Kesara Kumarasinghe",
      phone: "076 371 1928",
      role: "Technical Support"
    },
  ];

  return (
    <div className="max-w-screen-lg mx-auto p-6 pt-6 md:pt-2">
      <div className="text-center mb-16">
      <HeaderTitle title={"Get in Touch "}/>
        <p 
          className="text-_dark_lite text-lg max-w-2xl mx-auto sm:block md:hidden" 
          data-aos="fade-up" 
          data-aos-duration="600"
          data-aos-delay="100"
        >
          Contact our main branch or any of our regional representatives.
        </p>
        <p 
          className="text-_dark text-lg max-w-2xl mx-auto hidden sm:block md:hidden" 
          data-aos="fade-up" 
          data-aos-duration="600"
          data-aos-delay="100"
        >
          Contact our main branch or any of our regional representatives for product inquiries, support, or partnership opportunities.
        </p>
      </div>

      {/* Main Branch Card */}
      <div 
        className="bg-_white p-8 md:p-10 rounded-xl shadow-md border border-_blue border-opacity-10 mb-16"
        data-aos="fade-up"
        data-aos-duration="800"
      >
        <div className="text-center md:text-left">
          <h3 className="text-2xl font-bold text-_dark mb-3">
            Main Branch Office
          </h3>
          <p className="text-_dark_lite mb-6 max-w-md">
            Our headquarters is available 24/7 for your assistance through multiple channels.
          </p>
          
          <div className="flex flex-col md:flex-row md:space-x-12 md:items-center space-y-4 md:space-y-0">
            <div className="flex items-center">
              <RiPhoneFill className="text-_blue mr-3 size-5" />
              <a href="tel:0768372991" className="text-_dark hover:text-_blue transition-colors">
                076 837 2991
              </a>
            </div>
            
            <div className="flex items-center">
              <RiMailLine className="text-_blue mr-3 size-5" />
              <a href="mailto:contact@example.com" className="text-_dark hover:text-_blue transition-colors">
                doctorhub.lk@gmail.com
              </a>
            </div>
            
            <div className="flex items-center">
              <RiMapPinLine className="text-_blue mr-3 size-5" />
              <span className="text-_dark text-start">
                123 Business Center, Colombo
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Regional Representatives */}
      <div className="">
        <h3 
          className="text-2xl font-bold text-_bg_dark my-16 text-center md:text-left"
          data-aos="fade-up"
        >
          Universiy Representatives
        </h3>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {contacts.map((contact, index) => (
            <div
              key={index}
              className="bg-_white p-6 rounded-lg shadow-sm border border-_gray_lite hover:border-_blue hover:border-opacity-30 transition-all duration-300"
              data-aos="fade-up"
              data-aos-delay={100 + index * 50}
              data-aos-duration="600"
            >
              <h4 className="text-lg font-semibold text-_dark mb-1">
                {contact.title}
              </h4>
              <p className="text-_blue font-medium mb-1">
                {contact.name}
              </p>
              
              
              <div className="flex items-center mt-4 pt-4 border-t border-_gray_lite">
                <RiPhoneFill className="text-_blue mr-3 size-5" />
                <a 
                  href={`tel:${contact.phone.replace(/\s/g, '')}`} 
                  className="text-_dark hover:text-_blue transition-colors"
                >
                  {contact.phone}
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Contact;