import React from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { googleLogin } from "../../api/authApi";
import { toast } from "react-toastify";
const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const GoogleAuth = ({setIsLogged}) => {
  const navigate = useNavigate();

  const handleSuccess = async (response) => {
    console.log("Google Token:", response.credential);

    const data = await googleLogin(response.credential);

    console.log(data)
    if (data.authToken) {
      sessionStorage.setItem("token", data.authToken);
      toast.success("Successfully Sign In!");
      setIsLogged(true);

      const redirect = sessionStorage.getItem("redirect");
      redirect ? navigate(redirect) : data.isAdmin ? navigate("/admin") : navigate("/profile"); // TODO :: navigate proper location - Ramishka
    } else {
      console.error("Login failed:", data.error);
    }
  };

  const handleFailure = (error) => {
    console.error("Login Failed:", error);
  };

  return (

    <GoogleOAuthProvider clientId={clientId} >
      <GoogleLogin onSuccess={handleSuccess} onFailure={handleFailure} />
    </GoogleOAuthProvider>

  );
};

export default GoogleAuth;
