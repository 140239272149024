import React from "react";
import { Link } from "react-router-dom";
import "./Brief.css";
import bgImgB from "../../assets/brief2.png";
import bgImgC from "../../assets/brief3.png";
import mask from "./mask.svg";
import pulse from "./pulse.svg"
import { RiArrowRightLine } from "react-icons/ri";

function Brief() {
  return (
    <div>
        <div className="hidden lg:block">
    <section className="  w-full Brivef  flex-col items-center justify-center mt-48">
      {/* <img src={bgImg} alt="Brief background" className=" w-" /> */}
      <div className=" absolute   text-_white flex flex-col items-center justify-center  w-full">
        <div className="w-[360px] h-[280px] translate-x-64 translate-y-48">
        <h1 className="text-5xl font-black text-left" data-aos="zoom-in-right">Your Medical Journey Starts Here</h1>
        <p className=" font-semibold text-lg text-justify pt-2" data-aos="zoom-in-right">Whether you’re a student or a practicing doctor, Doctorhub.lk is here to support you every step of the way.</p>
        <Link to={"/store"} className="flex flex-row items-center justify-center gap-2 border w-48 rounded-full py-0.5 hover:bg-opacity-15 hover:bg-_dark  mt-6" data-aos="zoom-in-right">
        <p>Get Started Today</p>
        <RiArrowRightLine className=" translate-y-0.5"/>
        </Link>
        </div>
      </div>
      <div className=" h-[650px]   overflow-hidden  ">
      <img src={bgImgB} alt="Brief background" className="w-full h-full  object-cover object-center " />
    </div>
    </section>
    </div>


    {/* Tabs---------------------------------------------------------------------------------------- */}


    <section className=" hidden sm:block lg:hidden w-full Brivef  flex-col items-end justify-start mt-48 ">
      {/* <img src={bgImg} alt="Brief background" className=" w-" /> */}
      <div className=" absolute right-0 w-[400px] md:w-[500px] py-4 ml-48 mr-8 translate-x-48c translate-y-2c8 px-5 md:px-16 md:pt-16  text-_white inset- bg-gradient-to-b from-white/20 via-transparent to-transparent  rounded-xl flex flex-col gap-8">
        <h1 className="text-5xl font-black text-left" data-aos="zoom-in-right">Your Medical Journey Starts Here</h1>
        <p className=" font-semibold text-xl text-justify pt-2" data-aos="zoom-in-right">Whether you’re a student or a practicing doctor, Doctorhub.lk is here to support you every step of the way.</p>
        <Link className="flex flex-row items-center justify-center gap-2 border w-48 rounded-full py-0.5 hover:bg-opacity-15 hover:bg-_dark  mt-2 mb-4" data-aos="zoom-in-right">
        <p>Get Started Today</p>
        <RiArrowRightLine className=" translate-y-0.5"/>
        </Link>
      </div>
      <div className=" h-[650px]    overflow-hidden ">
      <img src={bgImgC} alt="Brief background" className="w-full h-full translate-x-56c object-cover object-center " />
    </div>
    </section>

      {/* Mobile---------------------------------------------------------------------------------------- */}


      <section className=" md:hidden w-full Brivef flex flex-col items-center justify-start mt-48 ">
      {/* <img src={bgImg} alt="Brief background" className=" w-" /> */}
      <div className=" absolute mx-4 py-4   translate-x-64x translate-y- px-5  text-_white inset- bg-gradient-to-b from-white/20 via-transparent to-transparent  rounded-xl flex flex-col gap-8">
        <h1 className=" text-4xl sm:text-5xl font-black text-left" data-aos="zoom-in-right">Your Medical Journey Starts Here</h1>
        <p className=" font-semibold text-lg sm:text-xl text-justify pt-2" data-aos="zoom-in-right">Whether you’re a student or a practicing doctor, Doctorhub.lk is here to support you every step of the way.</p>
        <Link className="flex flex-row items-center justify-center gap-2 border w-48 rounded-full py-0.5 hover:bg-opacity-15 hover:bg-_dark  mt-2 mb-4" data-aos="zoom-in-right">
        <p>Get Started Today</p>
        <RiArrowRightLine className=" translate-y-0.5"/>
        </Link>
      </div>
      <div className=" h-[400px] sm:h-[500px] mt-48    overflow-hidden ">
      <img src={bgImgC} alt="Brief background" className="w-full h-full translate-x-56c object-cover object-center " />
    </div>
    </section>
    </div>
  );
}

export default Brief;
