import { RiShoppingCartLine } from "react-icons/ri";
import ProductImage from "./ProductImage";
import StarRating from "../../components/StarRating/StarRating";

export default function ProductSection({
  product,
  selectedSubProduct,
  quantity,
  setQuantity,
  cart,
  handleAddToCart,
  handleBuyNow,
  setSelectedSubProduct,
  totalReviews
}) {
  return (
    <div className="">
      <div className="w-full flex flex-col md:flex-row items-start gap-6">
        {/* Image Section */}
        <div className="w-full md:w-1/2 ">
          <ProductImage imgs={product.images.map((img) => img.imageUrl)} />
        </div>

        {/* Description Section */}
        <div className="mx - w-full md:w-1/2 text-_dark text-start">
          <h1 className="text-lg sm:text-2xl md:text-xl lg:text-2xl font-semibold">
            {product.productDetails.title}
          </h1>
          <div className="flex flex-row items-center gap-4 py-3 sm:py-4">
            <StarRating rating={4} />
            <p className="text-sm text-_gray">{totalReviews} reviews</p>
          </div>

          <div className="hidden sm:block  items-baseline gap-4">
            <p className="text-4xl md:text-2xl lg:text-4xl font-semibold">
              Rs. {selectedSubProduct?.price}
            </p>
            <p className=" text-xl md:text-lg lg:text-xl font-semibold line-through text-_gray">
              Rs. {product.productDetails.price}
            </p>
            <p className="text-sm lg:text-base text-_red">7% off</p>
          </div>
          <div className="sm:hidden flex flex-col items-baseline gap-2 mt-">
            <p className="text-3xl sm:text-4xl md:text-2xl lg:text-4xl font-semibold">
              Rs. {selectedSubProduct?.price}
            </p>
            <div className="flex">
            <p className=" text-xl md:text-lg lg:text-xl font-semibold line-through text-_gray">
              Rs. {product.productDetails.price}
            </p>
            <p className="md:text-sm lg:text-base text-_red">7% off</p>
            </div>
            
          </div>

          {/* Subproduct Selection */}
          <div className="mt-4 lg:mt-8">
            <label className="block text-_gray mb-3">Choose Version:</label>
            <select
              className="border text-_dark rounded py-4 lg:text-lg px-2 mt-2 w-full focus:outline-_gray"
              value={selectedSubProduct?.subProductId}
              onChange={(e) =>
                setSelectedSubProduct(
                  product.subProducts.find(
                    (sp) => sp.subProductId === parseInt(e.target.value)
                  )
                )
              }
            >
              {product.subProducts.map((sp) => (
                <option key={sp.subProductId} value={sp.subProductId}>
                  {sp.title} - Rs. {sp.price}
                </option>
              ))}
            </select>
          </div>

          {/* Quantity Selection */}
          <div className="mt-4 lg:mt-8">
            <label className="block text-_gray mb-3">Quantity:</label>
            <input
              type="number"
              min="1"
              value={quantity}
              onChange={(e) => setQuantity(parseInt(e.target.value))}
              className="border rounded py-3 sm:py-4 text-lg px-2 mt-2 w-full focus:outline-_gray"
            />
          </div>

          {/* Buttons */}
          <div className="mt-8 sm:mt-16 lg:mt-20 flex flex-col sm:flex-row gap-6 sm:gap-3 w-full">
            <button
              className="bg-_dark text-white font-semiboldx text-lg sm:text-xl px-4 w-full sm:w-80 md:w-48 xl:w-72 py-3 sm:py-4 rounded-md hover:bg-opacity-95"
              onClick={handleBuyNow}
            >
              Buy Now
            </button>
            <button
              className={`px-6 py-3 sm:py-4 rounded-md text-_dark border w-full sm:w-60 ${
                cart.find(
                  (item) =>
                    item.subProductId === selectedSubProduct?.subProductId
                )
                  ? "bg-_white border-_red hover:bg-_gray_hover"
                  : "bg-_white border-_gray_lite hover:bg-_gray_hover"
              }`}
              onClick={handleAddToCart}
            >
              {cart.find(
                (item) =>
                  item.subProductId === selectedSubProduct?.subProductId
              ) ? (
                <div className="text-_red flex flex-row items-center justify-center gap-2 text-xl">
                  <RiShoppingCartLine />
                  <p>Remove from cart</p>
                </div>
              ) : (
                <div className="text-_dark flex flex-row items-center justify-center gap-2 text-xl">
                  <RiShoppingCartLine />
                  <p>Add to cart</p>
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}