import { createContext, useContext, useState, useEffect } from 'react';

const CartContext = createContext();

export function CartProvider({ children }) {
  const [cartItems, setCartItems] = useState([]);
  
  // Load cart from localStorage on initial render
  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem('cart') || '[]');
    setCartItems(savedCart);
  }, []);
  
  // Add item to cart
  const addToCart = (item) => {
    const existingItemIndex = cartItems.findIndex(
      (cartItem) => cartItem.subProductId === item.subProductId
    );
    
    let newCart;
    if (existingItemIndex >= 0) {
      // Update existing item
      newCart = [...cartItems];
      newCart[existingItemIndex] = {
        ...newCart[existingItemIndex],
        count: item.count
      };
    } else {
      // Add new item
      newCart = [...cartItems, item];
    }
    
    updateCart(newCart);
  };
  
  // Remove item from cart
  const removeFromCart = (subProductId) => {
    const newCart = cartItems.filter(item => item.subProductId !== subProductId);
    updateCart(newCart);
  };
  
  // Update item quantity
  const updateQuantity = (subProductId, newCount) => {
    if (newCount < 1) return;
    
    const newCart = cartItems.map(item =>
      item.subProductId === subProductId ? { ...item, count: newCount } : item
    );
    
    updateCart(newCart);
  };
  
  // Clear all items from cart
  const clearCart = () => {
    updateCart([]);
  };
  
  // Update both state and localStorage when cart changes
  const updateCart = (newCart) => {
    setCartItems(newCart);
    localStorage.setItem('cart', JSON.stringify(newCart));
  };
  
  return (
    <CartContext.Provider value={{
      cartItems,
      updateCart,
      addToCart,
      removeFromCart,
      updateQuantity,
      clearCart
    }}>
      {children}
    </CartContext.Provider>
  );
}

export function useCart() {
  return useContext(CartContext);
}